const mapper: Record<string, string> = {
  'en-US': 'en',
  fr: 'fr',
  'pt-BR': 'pt',
} as const;

export const languageMapper = (lang: string): string => mapper[lang] || lang;
export const languageUnmap = (lang: string): string => {
  const res = Object.entries(mapper).find(([key, value]) => value === lang);
  return res ? res[0] : lang;
};
