import {translationClientInstance} from '@src/Api/Graphql';
import {
  IAllTranslationFolderQuery,
  IAllTranslationFolderQueryVariables,
  AllTranslationFolderDocument,
} from '@src/Api/Graphql/gql/types.generated';
import { languageUnmap } from '@src/Services/i18n/languageMapper';

interface ILocalDataItem {
  node: {language: string; ns: string; data: object};
}

interface ILocalesData {
  edges: ILocalDataItem[];
}

const getRessources = async (language: string): Promise<ILocalesData> => {
  const edges: ILocalDataItem[] = [];
  const {data} = await translationClientInstance.query<
    IAllTranslationFolderQuery,
    IAllTranslationFolderQueryVariables
  >({
    query: AllTranslationFolderDocument,
    variables: {
      culture: languageUnmap(language),
    },
    fetchPolicy: 'no-cache',
  });

  if (
    data &&
    data.allTranslationFolder &&
    data.allTranslationFolder.items &&
    data.allTranslationFolder.items[0] &&
    data.allTranslationFolder.items[0].__typename === 'TranslationFolder' &&
    data.allTranslationFolder.items[0].children &&
    data.allTranslationFolder.items[0].children.items
  ) {
    data.allTranslationFolder.items[0].children.items.forEach(context => {
      if (context && context.__typename === 'TranslationContext') {
        const {name} = context;
        if (name) {
          const contextRessource: Record<string, any> = {};
          const contextName = name;

          if (context.children.items.length) {
            context.children.items.forEach(item => {
              if (item && item.__typename === 'TranslationItem' && name) {
                const {value, name: itemName} = item;
                if (value && itemName) {
                  contextRessource[itemName] = value;
                }
              }
            });
          }
          edges.push({
            node: {
              language,
              ns: contextName,
              data: contextRessource,
            },
          });
        }
      }
    });
  }

  return {
    edges,
  };
};

export default getRessources;
