import dayjs from 'dayjs';
import queryString from 'query-string';

// https://player.vimeo.com/external/807353098.m3u8?s=4740018836dd52d87cafc27d5d05db1cb2a921d5&oauth2_token_id=1702559564

const checkVimeoUrl = (vimeoUrl?: string): boolean => {
  if (vimeoUrl) {
    const {url, query} = queryString.parseUrl(vimeoUrl);
    if (url.indexOf('player.vimeo.com') >= 0) {
      if (query && typeof query.oauth2_token_id === 'string') {
        const diff = dayjs.unix(+query.oauth2_token_id).diff(dayjs(), 'second');
        if (diff > 30) {
          return true;
        }
      }
    }
  }

  return false;
};

export default checkVimeoUrl;
