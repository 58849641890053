import React, {Suspense, useEffect} from 'react';
import {Provider} from 'react-redux';
import store from './Store';
import {useApiClient} from 'native/src/Store/selector/useApiClient';

import {ApolloProvider} from '@apollo/client';
import InnerProvider from './Providers/InnerProvider/InnerProvider';
import {initAmplitude} from './Services/Amplitude/amplitudeInit';
import DidomiInitProvider from './Providers/DidomiInitProvider/DidomiInitProvider';
import PageLoader from './components/general/PageLoader/PageLoader';

export type RunContext = 'ssr' | 'browser';

interface IAppProviderProps {
  children: React.ReactElement;
  runContext: RunContext;
}

const AppProvider = (props: IAppProviderProps) => {
  const {children, runContext} = props;
  const {client} = useApiClient();

  useEffect(() => {
    if (runContext === 'browser') {
      initAmplitude();
    }
  }, [runContext]);

  return (
    <Provider store={store}>
      <DidomiInitProvider />
      <Suspense fallback={<PageLoader />}>
        {client && (
          <ApolloProvider client={client}>
            <InnerProvider>{children}</InnerProvider>
          </ApolloProvider>
        )}
      </Suspense>
    </Provider>
  );
};

export default AppProvider;
