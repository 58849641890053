import { useAppSelector } from "@src/Store/hooks";

export const useLanguages = () =>
  useAppSelector(state => state.language.languages);
export const useCurrentLanguage = () =>
  useAppSelector(state => state.language.currentLanguage);

export const useAllLanguage = () =>
  useAppSelector(state => state.language.allLng || {});

export const useMissingTranslation = () =>
  useAppSelector(state => state.language.missingTranslation || {});
