import {ContentResponse} from '~Api/Umbraco/interface/ContentResponse';
import {
  contentManagmentApi,
  UmbracoContentManagmentEndpointBuilder,
} from '../contentManagmentApi';

export const createContentEndpoint = (
  builder: UmbracoContentManagmentEndpointBuilder,
) => ({
  unPublishedContent: builder.query<any, string>({
    query: id => ({
      url: `/content/${id}/unpublish`,
      method: 'PUT',
      apiVersion: '2',
    }),
  }),
  getContent: builder.query<ContentResponse, string>({
    query: id => ({
      url: `/content/${id}`,
      method: 'GET',
      apiVersion: '2',
    }),
  }),
  postContent: builder.query<ContentResponse, {body: any}>({
    query: ({body}) => ({
      url: '/content',
      method: 'POST',
      apiVersion: '2',
      body,
    }),
    onQueryStarted: async (_arg, {dispatch, queryFulfilled}) => {
      try {
        const {data} = await queryFulfilled;
        if (data && data._id) {
          await dispatch(
            contentManagmentApi.endpoints.publishContent.initiate(data._id),
          );
        }
      } catch (error) {}
    },
  }),
  updateContent: builder.query<
    ContentResponse,
    {body: any; id: string; publish?: boolean}
  >({
    query: ({body, id}) => ({
      url: `/content/${id}`,
      method: 'PUT',
      apiVersion: '2',
      body,
    }),
    onQueryStarted: async ({publish = true}, {dispatch, queryFulfilled}) => {
      try {
        if (publish) {
          const {data} = await queryFulfilled;
          if (data && data._id) {
            dispatch(
              contentManagmentApi.endpoints.publishContent.initiate(data._id),
            );
          }
        }
      } catch (error) {}
    },
  }),
  deleteContent: builder.query<any, string>({
    query: id => ({
      url: `/content/${id}`,
      method: 'DELETE',
      apiVersion: '2',
    }),
  }),
  publishContent: builder.query<ContentResponse, string>({
    query: id => ({
      url: `/content/${id}/publish`,
      method: 'PUT',
      apiVersion: '2',
    }),
  }),
});
