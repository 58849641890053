import {responsiveMap} from 'design-system/src/WebComponent/Grid/_utils/responsiveObserve';
import styled from 'styled-components';

export const _DefaultHeaderRoot = styled.div(() => {
  return {
    // padding: '40px 60px 13px',
    padding: '0',
    display: 'flex',
    flexDirection: 'row',
    // position: 'static',
    top: 0,
    left: 0,
    zIndex: 50,
    width: '100%',
    // [`@media ${responsiveMap.mobile}`]: {
    //     padding: '40px 16px 13px',
    // },
    height: 0,
    position: 'relative',
    '.logo': {
      position: 'absolute',
      left: 60,
      top: 50,
      transform: 'translateY(-50%)',
      zIndex: 10,
      [`@media ${responsiveMap.mobile}`]: {
        left: 16,
      },
    },
    '.lang-switcher': {
      position: 'absolute',
      right: 60,
      top: 50,
      transform: 'translateY(-50%)',
      zIndex: 1051,
      [`@media ${responsiveMap.mobile}`]: {
        right: 16,
      },
    },
  };
});
