import {StyleSheet, Platform} from 'react-native';
import defaultTheme from '../../Theme/defaultTheme';
import Metrics from '../../Theme/Metrics';

export const buttonStyles = StyleSheet.create({
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    flexGrow: 0,
    borderWidth: 1,
    borderColor: defaultTheme.primary,
  },
  buttonContainer: {
    alignItems: 'flex-start',
    // flex: 1,
  },
  iconLeft: {
    marginRight: Metrics.horizontalScale(6),
  },
  fullWidth: {
    flexGrow: 1,
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'stretch',
    alignItems: 'stretch',
    justifyContent: 'center',
  },
});

export const buttonSizeStyles = StyleSheet.create({
  xlarge: {
    borderRadius: Metrics.moderateScale(180),
    paddingHorizontal: Metrics.horizontalScale(76.5),
    paddingVertical: Metrics.verticalScale(15),
  },
  large: {
    borderRadius: Metrics.moderateScale(180),
    paddingHorizontal: Metrics.horizontalScale(32),
    paddingVertical: Metrics.verticalScale(18),
  },
  medium: {
    borderRadius: Metrics.moderateScale(180),
    paddingHorizontal: Metrics.horizontalScale(30),
    paddingVertical: Metrics.verticalScale(16),
  },
  small: {
    borderRadius: Metrics.moderateScale(48),
    paddingHorizontal: Metrics.horizontalScale(24),
    paddingVertical: Metrics.horizontalScale(12),
  },
  xsmall: {
    borderRadius: Metrics.moderateScale(48),
    paddingHorizontal: Metrics.horizontalScale(16),
    paddingVertical: Metrics.verticalScale(9),
  },
  h2: {
    borderRadius: Metrics.moderateScale(180),
    paddingHorizontal: Metrics.horizontalScale(28),
    paddingVertical: Metrics.verticalScale(12),
  },
});

export const buttonVariantStyles = StyleSheet.create({
  outlined: {
    borderWidth: 1,
    borderColor: defaultTheme.primary,
    color: defaultTheme.primary,
  },
  contained: {
    borderWidth: 1,
    borderColor: defaultTheme.primary,
    backgroundColor: defaultTheme.primary,
    color: defaultTheme.white,
  },
  filled: {
    borderWidth: 1,
    borderColor: defaultTheme.filets,
    backgroundColor: defaultTheme.filets,
    color: defaultTheme.white,
  },
  disabled: {
    borderWidth: 1,
    borderColor: defaultTheme.gray,
    color: defaultTheme.gray,
    backgroundColor: 'transparent',
  },
});
