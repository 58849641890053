import dayjs from 'dayjs';
import {TvGuidResponse} from '~Api/FreeApi/interface/tvGuide';

export interface ITvGuideItem {
  title: string;
  type: string;
  durationDisplay: string;
  duration: number;
  progress: number;
  start: Date;
  stop: Date;
}

export class TvModel {
  constructor(private tv: TvGuidResponse) {}

  getGuide(
    startDate: Date = new Date(),
    displayEndDate?: Date,
  ): ITvGuideItem[] {
    const guides: ITvGuideItem[] = [];
    const {tv} = this.tv;

    if (tv && tv.programme) {
      const endDate: Date =
        displayEndDate || dayjs(startDate).endOf('day').toDate();

      tv.programme.forEach(item => {
        if (item) {
          const {
            $: {start, stop},
            title,
            'sub-title': subTitle,
          } = item;

          if (
            dayjs(stop).isAfter(startDate) &&
            (dayjs(stop).isBefore(endDate) || dayjs(start).isBefore(endDate))
          ) {
            const duration = Math.abs(dayjs(stop).diff(start, 'minutes'));
            // const progressDiff = dayjs(startDate).diff(start, 'minutes');
            guides.push({
              title: (title && title[0] && title[0]._) || '',
              durationDisplay: duration + ' min',
              duration: duration,
              progress: 0,
              type: (subTitle && subTitle[0] && subTitle[0]._) || '',
              start,
              stop,
            });
          }
        }
      });
    }

    return guides;
  }

  getCurrentProgram(
    now: Date = new Date(),
  ): (ITvGuideItem & {start: Date; stop: Date}) | undefined {
    const {tv} = this.tv;
    if (tv && tv.programme) {
      for (let index = 0; index < tv.programme.length; index++) {
        const item = tv.programme[index];
        const {
          $: {start, stop},
          title,
          'sub-title': subTitle,
        } = item;
        if (dayjs(now).isAfter(start) && dayjs(now).isBefore(stop)) {
          const duration = dayjs(stop).diff(start, 'minutes');
          const progressDiff = dayjs(now).diff(start, 'minutes');
          return {
            title: (title && title[0] && title[0]._) || '',
            durationDisplay: duration + ' min',
            duration: duration,
            progress: progressDiff / duration,
            type: (subTitle && subTitle[0] && subTitle[0]._) || '',
            start,
            stop,
          };
        }
      }
    }

    return;
  }
}
