import {TraceApiEndpointBuilder} from '../traceApi';

export const createAwardsEndpoint = (builder: TraceApiEndpointBuilder) => ({
  awardsVoting: builder.query<any, {formId: string; body: Record<string, any>}>({
    query: ({formId, body}) => ({
      url: '/awards/voting',
      method: 'POST',
      body: {
        formId,
        ...body,
      },
    }),
  }),
});
