import React from 'react';
import {StyleSheet, View} from 'react-native';
import Metrics, {width} from '../../../Theme/Metrics';
import BarLoader from '../ComponentLoader/BarLoader/BarLoader';

const CustomHeaderLoader = () => {
  return (
    <View style={customHeaderLoaderStyles.container}>
      <View
        style={{
          alignItems: 'center',
        }}>
        <BarLoader type="large" />
        <View style={customHeaderLoaderStyles.info}>
          <BarLoader type="xsmall" />
          <BarLoader
            type="xxsmall"
            style={{marginHorizontal: Metrics.horizontalScale(8)}}
          />
          <BarLoader type="xsmall" />
        </View>
        <BarLoader type="button" />
      </View>
    </View>
  );
};

export default CustomHeaderLoader;

const customHeaderLoaderStyles = StyleSheet.create({
  container: {
    width: width,
    height: Metrics.verticalScale(480),
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  info: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: Metrics.verticalScale(11),
    marginBottom: Metrics.verticalScale(23),
  },
});
