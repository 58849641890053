import {AxiosRequestConfig, AxiosError} from 'axios';
import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import {freeAxiosInstance} from './free.axios';

export const freeBaseQueryFn: BaseQueryFn<
  {
    url: string;
    baseUrl?: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
  },
  unknown,
  unknown,
  {}
> = async ({url, method, body: data, params}) => {
  try {
    const result = await freeAxiosInstance({
      url,
      method,
      data,
      params,
    });

    return {
      data: result?.data,
      error: undefined,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {status: err.response?.status, data: err.response?.data},
      data: undefined,
    };
  }
};
