import useGetVimeoVideo from '@src/Api/TraceApi/Hooks/useGetVimeoVideo';
import {useGetLanguagesQuery} from '@src/Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import PageLoader from '@src/components/general/PageLoader/PageLoader';
import ThemeProvider from 'design-system/src/Provider/ThemeProvider/ThemeProvider';
import VimeoProvider from 'design-system/src/Provider/VimeoProvider/VimeoProvider';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import GridGlobalCss from 'design-system/src/WebComponent/Grid/GridGlobalCss';
import React from 'react';
import AppTrackingProvider from '../AppTrackingProvider/AppTrackingProvider';

interface IInnerProviderProps {
  children: React.ReactElement;
}

const InnerProvider = (props: IInnerProviderProps) => {
  const {children} = props;
  const {isLoading} = useGetLanguagesQuery();
  const {getVimeoVideo} = useGetVimeoVideo();

  return (
    <ThemeProvider
      theme={{
        ...defaultTheme,
      }}>
      <GridGlobalCss />
      <VimeoProvider
        value={{
          getVimeoVideo,
        }}>
        <AppTrackingProvider>
          {isLoading ? <PageLoader /> : children}
        </AppTrackingProvider>
      </VimeoProvider>
    </ThemeProvider>
  );
};

export default InnerProvider;
