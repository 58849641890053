import React from 'react';
import ContentLoader, {Rect} from 'react-content-loader/native';
import {StyleSheet} from 'react-native';
import Metrics, {height, width} from '../../../Theme/Metrics';

const TextInputLoader = () => {
  const customHeight = Metrics.verticalScale(50);
  const customWidth = Metrics.horizontalScale(width - 66 );

  return (
    <ContentLoader
      style={StyleSheet.flatten([
        {
          backgroundColor: 'rgba(23, 24, 29, 0.7)',
          borderRadius: Metrics.verticalScale(customHeight * 0.48),
          overflow: 'hidden',
          marginBottom: Metrics.verticalScale(20),
        },
      ])}
      height={customHeight}
      width={customWidth}
      speed={1}
      backgroundColor={'rgba(23, 24, 29, 0.7)'}
      foregroundColor={'#111216'}>
      <Rect
        x="0"
        y="0"
        rx="16"
        ry="16"
        width={`${customWidth}`}
        height={`${customHeight}`}
        fill={'#111216'}
      />
    </ContentLoader>
  );
};

export default TextInputLoader;
