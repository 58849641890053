import {useLazyGetPublicVimeoVideoQuery} from '../traceApi';

const useGetVimeoVideo = () => {
  const [fetchVimeoVideo] = useLazyGetPublicVimeoVideoQuery();

  const getVimeoVideo = async (id: string) => {
    const response = await fetchVimeoVideo(id);
    if (response && response.data) {
      return {
        uri: response.data.hls,
        poster: response.data.picture,
      };
    }
    return;
  };

  return {
    getVimeoVideo,
  };
};

export default useGetVimeoVideo;
