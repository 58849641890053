import React from 'react';
import {TrackingContextProvider, TrackingState} from './TrackingContext';

interface ITrackingProviderProps {
  events?: Partial<TrackingState>;
  children: React.ReactNode;
}

const TrackingProvider = (props: ITrackingProviderProps) => {
  const {events = {}, children} = props;
  return (
    <TrackingContextProvider
      value={{
        ...events,
      }}>
      {children}
    </TrackingContextProvider>
  );
};

export default TrackingProvider;
