const md = 768;
const lg = 993;

export const size = {
  xxs: 320,
  xs: 479,
  sm: 480,
  md: 768,
  lg: lg,
  xl: 1281,
  xxl: 1600,
  maxLg: lg,
  //
  mobile: md - 1,
};

export const maxSize = 1920;
