import {QueryResult} from '@apollo/client';
import {
  IAwardSettingsQuery,
  Exact,
  InputMaybe,
} from '@src/Api/Graphql/gql/types.generated';
import React, {createContext} from 'react';

export interface AwardState {
  settings?: QueryResult<
    IAwardSettingsQuery,
    Exact<{
      culture?: InputMaybe<string>;
    }>
  >;
}

const AwardContext = createContext<AwardState>({});

const AwardContextProvider = AwardContext.Provider;

const useAwardContext = () => {
  return React.useContext<AwardState>(AwardContext);
};

export {AwardContextProvider, useAwardContext};
export default AwardContext;
