import React from 'react';
import {VimeoContextProvider, VimeoState} from './VimeoContext';
import {ReactNode} from 'react';

interface IVimeoProviderProps {
  value?: Partial<VimeoState>;
  children: ReactNode;
}

const VimeoProvider = (props: IVimeoProviderProps) => {
  const {value = {}, children} = props;
  return (
    <VimeoContextProvider
      value={{
        ...value,
        getVimeoVideo:
          value.getVimeoVideo ||
          (id =>
            Promise.resolve({
              uri: id,
            })),
      }}>
      {children}
    </VimeoContextProvider>
  );
};

export default VimeoProvider;
