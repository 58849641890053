// const dotenv = require('dotenv');
// dotenv.config();

type EnvNode = 'dev' | 'staging' | 'prod';

const getEnvValue = (
  prod: string | undefined,
  dev?: string | undefined,
  staging?: string | undefined,
): string => {
  const nodeEnv = (process.env.GATSBY_ENV_MODE as EnvNode) || 'prod';
  if (nodeEnv === 'dev') {
    return dev || '';
  } else if (nodeEnv === 'staging') {
    return staging || '';
  } else {
    return prod || '';
  }
};
const umbProjectAlias: string =
  getEnvValue(
    process.env.GATSBY_UMB_PROJECT_ALIAS || 'dev-trace-back-office',
    process.env.GATSBY_UMB_PROJECT_ALIAS_DEV,
    process.env.GATSBY_UMB_PROJECT_ALIAS_STAGING,
  ) || '';
const traceApiUrl: string =
  getEnvValue(
    process.env.GATSBY_TRACE_API_URL ||
      'https://us-central1-trace-plus-1338c.cloudfunctions.net/dev/',
    process.env.GATSBY_TRACE_API_URL_DEV,
    process.env.GATSBY_TRACE_API_URL_STAGING,
  ) || '';

const envMode =
  umbProjectAlias.indexOf('dev') >= 0 ||
  umbProjectAlias.indexOf('stage') >= 0 ||
  traceApiUrl.indexOf('dev') >= 0
    ? 'dev'
    : process.env.GATSBY_ENV_MODE || 'prod';

const apiConfig = {
  envMode: envMode,
  graphqlUrl: process.env.GATSBY_GRAPHQL_URL || '',
  restUrl: process.env.GATSBY_REST_URL || '',
  contentManagmentUrl: process.env.GATSBY_CM_REST_URL || '',
  umbApiKey:
    getEnvValue(
      process.env.GATSBY_UMB_API_KEY || 'vEPjYzCbiNqkW1RZiHTf',
      process.env.GATSBY_UMB_API_KEY_DEV,
      process.env.GATSBY_UMB_API_KEY_STAGING,
    ) || '',
  umbProjectAlias: umbProjectAlias,
  traceApiUrl: traceApiUrl,
  livestreamClientId: process.env.GATSBY_LIVESTREAM_CLIENT_ID || '',
  umbBoUrl:
    getEnvValue(
      process.env.GATSBY_UMB_BO_URL ||
        'https://dev-trace-back-office.euwest01.umbraco.io',
      process.env.GATSBY_UMB_BO_URL_DEV,
      process.env.GATSBY_UMB_BO_URL_STAGING,
    ) || '',
  didomiApiKey: process.env.GATSBY_DIDOMI_API_KEY || '',
  didomiNoticeId: process.env.GATSBY_DIDOMI_NOTICE_ID || '',
  appsFlyerDevKey: process.env.GATSBY_APPS_FLYER_DEV_KEY || '',
  appiOSId: process.env.GATSBY_APP_IOS_ID || '',
  amplitude: {
    apiKey:
      getEnvValue(
        process.env.GATSBY_AMPLITUDE_API_KEY ||
          '4e348184dba27e4c0c3fddfe19bde74a',
        process.env.GATSBY_AMPLITUDE_API_KEY_DEV,
        process.env.GATSBY_AMPLITUDE_API_KEY_STAGING,
      ) || '',
    debugLevel:
      envMode !== 'prod'
        ? 3
        : process.env.GATSBY_AMPLITUDE_DEBUG_LEVEL
        ? parseInt(process.env.GATSBY_AMPLITUDE_DEBUG_LEVEL, 10)
        : 0,
  },
  freemiumProductId:
    process.env.GATSBY_FREEMIUM_PRODUCT_ID || 'trace.subscription.free',
  iosSharedSecret: process.env.GATSBY_IOS_SHARED_SECRET || '',
  techCookiePurpId: process.env.GATSBY_TECHNICAL_COOKIE || '',
  geolocPurpId: process.env.GATSBY_GEOLOCALISATION || '',
  appsFlyerPurpId: process.env.GATSBY_APPS_FLYER || '',
  amplitudePurpId: process.env.GATSBY_AMPLITUDE_PURPOSE_ID || '',
  mcContentPurpId: process.env.GATSBY_MC_CONTENT || '',
  mcStandardAdsPurpId: process.env.GATSBY_MC_STANDARD_ADS || '',
  mcPersonalisedPurpId: process.env.GATSBY_MC_PERSONALISED_ADS || '',
  commingSoonEnabled: process.env.GATSBY_COMMIN_SOON_ENABLED || '',
  recaptchSiteKey: process.env.GATSBY_RECAPTCHA_SITE_KEY || '',
};

export default apiConfig;
