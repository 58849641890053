import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ITvGuideItem} from '~Models/TvModel';

interface ChannelState {
  guide: Record<string, ITvGuideItem[]>;
}

const initialState = {
  guide: {},
} as ChannelState;

// Then, handle actions in your reducers:
const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setChannelGuide(
      state,
      action: PayloadAction<{id: string; data: ITvGuideItem[]}>,
    ) {
      const {id, data} = action.payload;
      state.guide[id] = data;
      return state;
    },
  },
});

export default channelSlice;
