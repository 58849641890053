import Constants from './Constant';

export enum CardSizeType {
  small,
  medium,
  big,
}

export type CardSizeOptions<T = boolean> = {
  [key in keyof typeof CardSizeType]: T;
};

export type CardSize =
  | 'small'
  | 'medium'
  | 'big'
  | 'xsmall'
  | 'channel'
  | 'news'
  | 'xsmall-news'
  | 'top10';

export const selectSize: <
  T extends Partial<{
    width: number;
    height: number;
    type?: CardSize;
  }>,
>(
  argProps: T,
  isLearnType?: boolean,
) => {width: number; height: number} = (argProps, isLearnType) => {
  const {type = 'big', width, height} = argProps;
  let size = {width: 0, height: 0};
  if (type === 'small') {
    size = {
      width: Constants.SMALL_WIDTH,
      height: !isLearnType ? Constants.SMALL_HEIGHT : Constants.LEARN_SHEIGHT,
    };
  } else if (type === 'big' || type === 'top10') {
    size = {
      width: Constants.BIG_WIDTH,
      height: Constants.BIG_HEIGHT,
    };
  } else if (type === 'medium') {
    size = {
      width: Constants.MEDIUM_WIDTH,
      height: !isLearnType ? Constants.MEDIUM_HEIGHT : Constants.LEARN_MHEIGHT,
    };
  } else if (type === 'xsmall' || type === 'xsmall-news') {
    size = {
      width: Constants.XSMALL_WIDTH,
      height: !isLearnType ? Constants.XSMALL_HEIGHT : Constants.LEARN_XSHEIGHT,
    };
  } else if (type === 'channel') {
    size = {
      width: 168,
      height: 94,
    };
  } else if (type === 'news') {
    size = {
      width: Constants.SMALL_WIDTH,
      height: Constants.NEWS_HEIGHT,
    };
  } else {
    size = {
      width: Constants.BIG_WIDTH,
      height: Constants.BIG_HEIGHT,
    };
  }
  if (width) {
    size.width = width;
  }
  if (height) {
    size.height = height;
  }
  return size;
};
