import {useAwardSettingsQuery} from '@src/Api/Graphql/gql/types.generated';
import apiConfig from '@src/Configs/apiConfig';
import {InnerLayoutProps} from '@src/LayoutProvider';
import AwardsThemeProvider from '@src/Providers/AwardsThemeProvider/AwardsThemeProvider';
import ThankYouPage from '@src/Umbraco/Pages/ThankYouPage/ThankYouPage';
import React, {ReactNode} from 'react';
import DefaultHeader from '../DefaultLayout/DefaultHeader/DefaultHeader';
import {AwardContextProvider} from './AwardLayout.context';
import {_AwardLayoutRoot} from './AwardLayout.styled';

export interface IAwardCategoryLayoutProps extends InnerLayoutProps {
  children: ReactNode;
  variant?: 'category' | 'default' | 'modal';
}

const AwardLayout = (props: IAwardCategoryLayoutProps) => {
  const {children, variant = 'default'} = props;
  const settingResult = useAwardSettingsQuery({
    skip: typeof window === 'undefined',
  });

  const commmingSoonPageId =
    settingResult.data?.allAwardsHomepage?.items?.[0]?.comingSoonPage?.id || '';
  const isCommintSoon = apiConfig.commingSoonEnabled && !!commmingSoonPageId;

  return (
    <AwardsThemeProvider>
      <AwardContextProvider
        value={{
          settings: settingResult,
        }}>
        <_AwardLayoutRoot $variant={variant} className="award-layout">
          {variant !== 'modal' && <DefaultHeader hideLogo={true} />}
          <main>
            {isCommintSoon ? (
              <ThankYouPage id={commmingSoonPageId} fullScreen={true} />
            ) : (
              children
            )}
          </main>
        </_AwardLayoutRoot>
      </AwardContextProvider>
    </AwardsThemeProvider>
  );
};

export default AwardLayout;
