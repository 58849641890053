import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface ILayoutHistory {
  uri: string;
  element: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

interface LayoutState {
  history: ILayoutHistory[];
}

const initialState = {
  history: [],
} as LayoutState;

// Then, handle actions in your reducers:
const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    addHistory(state, action: PayloadAction<ILayoutHistory>) {
      if (state.history.length > 0) {
        const last = state.history[state.history.length - 1];
        if (last.uri !== action.payload.uri) {
          state.history = [...state.history, action.payload];
        }
      } else {
        state.history = [action.payload];
      }

      if (state.history.length > 3) {
        state.history = state.history.slice(1);
      }
      return state;
    },
  },
});

export default layoutSlice;
