import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ILanguage} from '~Api/Umbraco/ContentManagmentApi/endpoints/language.endpoints';

interface LanguageState {
  currentLanguage: string;
  languages: ILanguage[];
  contentIdOfContext: Record<string, string | undefined>;
  allLng: Record<string, Record<string, string>>;
  missingTranslation: Record<string, Record<string, Record<string, boolean>>>;
}

const initialState = {
  currentLanguage: 'en-US',
  languages: [],
  contentIdOfContext: {},
  allLng: {'en-US': {}},
  missingTranslation: {},
} as LanguageState;

// Then, handle actions in your reducers:
const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setCurrentLanguage(state, action: PayloadAction<string>) {
      const newLanguage = action.payload;
      state.currentLanguage = newLanguage;
      return state;
    },
    setLanguages(state, action: PayloadAction<ILanguage[]>) {
      state.languages = action.payload;
      return state;
    },
    setContextId(state, action: PayloadAction<{name: string; id: string}>) {
      const {name, id} = action.payload;
      state.contentIdOfContext[name] = id;
    },
    setAllLng(
      state,
      action: PayloadAction<{lng: string; ns: string; data: any}>,
    ) {
      const {lng, ns, data} = action.payload;
      if (!state.allLng[lng]) {
        state.allLng[lng] = {};
      }
      state.allLng[lng][ns] = data;
      return state;
    },
    addLng(state, action: PayloadAction<{lng: string; data: any}>) {
      const {lng, data} = action.payload;
      if (!state.allLng) {
        state.allLng = {};
      }
      state.allLng[lng] = data;
      return state;
    },
    addSendedTranslation(
      state,
      action: PayloadAction<{lng: string; ns: string; key: string}>,
    ) {
      const {lng, ns, key} = action.payload;
      if (!state.missingTranslation) {
        state.missingTranslation = {};
      }
      if (!state.missingTranslation[lng]) {
        state.missingTranslation[lng] = {};
      }
      if (!state.missingTranslation[lng][ns]) {
        state.missingTranslation[lng][ns] = {};
      }
      state.missingTranslation[lng][ns][key] = true;

      return state;
    },
  },
});

export default languageSlice;
