export interface Theme {
  fontFamily: string;
  primary: string;
  white: string;
  primaryNavBar: string;
  gray: string;
  black: string;
  primaryTitle: string;
  primaryInfo: string;
  innerBorder: string;
  filets: string;
  textInput: {
    primary: string;
    bg: string;
  };
  tag: {
    primary: string;
    secondary: string;
    tertiary: string;
  };
  option: {
    default: string;
    defaultBackground: string;
    valideBackground: string;
    invalide: string;
    invalideBackground: string;
    success: string;
    successBackground: string;
    failedBackground: string;
  };
  iconCard: {
    primaryActive: string;
    secondaryActive: string;
    primaryDefault: string;
    secondaryDefault: string;
  };
  subscribe: {
    primary: string;
  };
  player: {
    background: string;
  };
  typography: {
    color: {
      primary: string;
      gray: string;
      white: string;
      black: string;
      orange: string;
    };
  };
  card: {
    bg: string;
    vodTheme: {
      primary: string;
      secondary: string;
      tertiary: string;
    };
  };
  playList: {
    primary: string;
    secondary: string;
  };
  input: {
    activeBorderColor: string;
    borderColor: string;
  };
  page: {
    background: string;
  };
  button: {
    danger: string;
  };
}

const defaultTheme: Theme = ((): Theme => {
  const primary = '#EA6C13';
  const white = '#FFFFFF';
  const black = '#000000';
  const gray = '#A0A0A0';
  const filets = '#32343E';
  const orange = '#EA6C13';

  return Object.freeze<Theme>({
    fontFamily: 'SuisseIntl-Regular',
    primary,
    white,
    black,
    gray,
    filets,
    primaryTitle: white,
    primaryInfo: gray,
    primaryNavBar: '#1B1C21',
    playList: {
      primary: '#202021',
      secondary: '#8E7D76',
    },
    textInput: {
      primary: '#2F3139',
      bg: '#16181E',
    },
    tag: {
      primary: '#BE030A',
      secondary: '#013ACC',
      tertiary: '#2F82FF',
    },
    option: {
      default: '#2F3139',
      defaultBackground: '#16181E',
      valideBackground: 'rgba(234, 108, 19, 0.13)',
      invalide: '#BE030A',
      invalideBackground: 'rgba(255, 30, 30, 0.13)',
      success: 'rgba(5, 201, 107, 0.8)',
      successBackground: 'rgba(5, 201, 107, 0.2)',
      failedBackground: 'rgba(255, 30, 30, 0.2)',
    },
    subscribe: {
      primary: 'rgba(234, 108, 19, 0.5)',
    },
    innerBorder: '#393B45',
    iconCard: {
      primaryActive: '#FA6217',
      secondaryActive: '#FF9D1D',
      primaryDefault: '#2A2C33',
      secondaryDefault: '#363941',
    },
    typography: {
      color: {
        primary,
        gray,
        white,
        black,
        orange,
      },
    },
    card: {
      bg: '#4A4A4A',
      vodTheme: {
        primary: '#978A9B',
        secondary: '#9F907A',
        tertiary: '#68879F',
      },
    },
    player: {
      background: '#2A2B32',
    },
    page: {
      background: '#111216',
    },
    input: {
      activeBorderColor: 'rgba(234, 108, 19, 0.5)',
      borderColor: '#2F3139',
    },
    button: {
      danger: '#B31919',
    },
  });
})();

export default defaultTheme;
