import {IVimeoVideo} from '../interface/vimeoVideo.interface';
import {TraceApiEndpointBuilder} from '../traceApi';
import vimeoSlice from '~Store/Slices/Vimeo/Vimeo.slice';

export const createVimeoEndpoint = (builder: TraceApiEndpointBuilder) => ({
  getVimeoVideo: builder.query<IVimeoVideo, string>({
    forceRefetch: ({currentArg: id, state}) => {
      const rootState = state as any;
      return !(
        id &&
        rootState &&
        rootState.vimeo &&
        rootState.vimeo.videos &&
        rootState.vimeo.videos[id]
      );
    },
    async queryFn(id, {getState}, __, baseQuery) {
      try {
        const rootState = getState() as any;
        if (
          rootState &&
          rootState.vimeo &&
          rootState.vimeo.videos &&
          !!rootState.vimeo.videos[id]
        ) {
          return {
            data: rootState.vimeo.videos[id] as IVimeoVideo,
            meta: {},
          };
        }
        const response = await baseQuery({
          url: `/vimeo/videos/${id}`,
          method: 'GET',
        });

        return {
          data: response.data as IVimeoVideo,
          error: response.error as any,
          meta: response.meta,
        };
      } catch (err) {
        return {
          error: {status: '', data: ''},
          data: undefined,
          meta: undefined,
        };
      }
    },
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      // `onStart` side-effect
      try {
        const {data} = await queryFulfilled;
        if (data && data.id) {
          dispatch(
            vimeoSlice.actions.addVideosData({
              id,
              video: data,
            }),
          );
        }
      } catch (err) {
        // `onError` side-effect
      }
    },
  }),
  getPublicVimeoVideo: builder.query<IVimeoVideo, string>({
    forceRefetch: ({currentArg: id, state}) => {
      return true
    },
    async queryFn(id, {getState}, __, baseQuery) {
      try {
        // const rootState = getState() as any;
        // if (
        //   rootState &&
        //   rootState.vimeo &&
        //   rootState.vimeo.videos &&
        //   !!rootState.vimeo.videos[id]
        // ) {
        //   return {
        //     data: rootState.vimeo.videos[id] as IVimeoVideo,
        //     meta: {},
        //   };
        // }
        const response = await baseQuery({
          url: `/vimeo/public-video/${id}`,
          method: 'GET',
        });

        return {
          data: response.data as IVimeoVideo,
          error: response.error as any,
          meta: response.meta,
        };
      } catch (err) {
        return {
          error: {status: '', data: ''},
          data: undefined,
          meta: undefined,
        };
      }
    },
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      // `onStart` side-effect
      try {
        const {data} = await queryFulfilled;
        if (data && data.id) {
          dispatch(
            vimeoSlice.actions.addVideosData({
              id,
              video: data,
            }),
          );
        }
      } catch (err) {
        // `onError` side-effect
      }
    },
  }),
});
