import React, {PropsWithChildren} from 'react';
import {ImageBackground, View, Image, StyleSheet} from 'react-native';

import {CustomFastImageProps} from './CustomFastImageProps';

const CustomFastImage = (props: PropsWithChildren<CustomFastImageProps>) => {
  const {
    width,
    height,
    children,
    source,
    style,
    wrapperStyles,
    radius,
    isNotSetContainerHeight,
  } = props;
  const uri = typeof source === 'number' ? '' : source?.uri;
  const flattenStyle = StyleSheet.flatten([style || {}]);
  return (
    <View
      style={[
        {
          width: width,
          height: isNotSetContainerHeight ? undefined : height,
          borderRadius: radius || flattenStyle.borderRadius,
          overflow: 'hidden',
        },
        wrapperStyles,
      ]}>
      {children !== undefined ? (
        <ImageBackground
          style={[
            {
              width: width,
              height: height,
              borderRadius: radius || flattenStyle.borderRadius,
              borderBottomLeftRadius: flattenStyle.borderBottomLeftRadius,
              borderBottomRightRadius: flattenStyle.borderBottomRightRadius,
              borderTopLeftRadius: flattenStyle.borderTopLeftRadius,
              borderTopRightRadius: flattenStyle.borderTopRightRadius,
            },
            style,
          ]}
          source={{uri}}
          resizeMode="cover">
          {children}
        </ImageBackground>
      ) : (
        <Image
          style={{
            width: width,
            height: height,
            ...flattenStyle,
            borderRadius: radius || flattenStyle.borderRadius,
            borderBottomLeftRadius: flattenStyle.borderBottomLeftRadius,
            borderBottomRightRadius: flattenStyle.borderBottomRightRadius,
            borderTopLeftRadius: flattenStyle.borderTopLeftRadius,
            borderTopRightRadius: flattenStyle.borderTopRightRadius,
          }}
          source={{uri}}
        />
      )}
    </View>
  );
};

export default CustomFastImage;
