import {configureStore} from '@reduxjs/toolkit';
import { freeApi } from '@src/Api/FreeApi/freeApi';
import { traceApi } from '@src/Api/TraceApi/traceApi';
import { contentManagmentApi } from '@src/Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import { umbracoCdnApi } from '@src/Api/Umbraco/ContentManagmentApi/UmbracoCdnApi/umbracoCdnApi';
import rootReducer from './reducers';

function configureAppStore() {
  const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      })
        .concat(contentManagmentApi.middleware)
        .concat(traceApi.middleware)
        .concat(freeApi.middleware)
        .concat(umbracoCdnApi.middleware),
    ],
  });

  return store;
}
const store = configureAppStore();
export type RootState = ReturnType<typeof store.getState>;

export default store;
