import React from 'react';
import {Text} from 'react-native';
import {SvgProps} from 'react-native-svg';
import Icones, {IconName, IconSize} from '../../assets/Icones';
import {useThemeContext} from '../../Provider/ThemeProvider/ThemeContext';
import defaultTheme from '../../Theme/defaultTheme';
import Metrics from '../../Theme/Metrics';

export interface IIconProps extends SvgProps {
  name: IconName;
  size?: IconSize;
  color?: string;
  autoResize?: boolean;
}

const Icon = (props: IIconProps) => {
  let {
    name,
    size = '24',
    autoResize,
    color = defaultTheme.white,
    ...restProps
  } = props;
  const {component} = useThemeContext();
  const {mapColor} = component?.typography || {};
  if (name === 'favoris-fill') {
    color = defaultTheme.primary;
  }
  if (Icones[name]) {
    const hasSize: boolean = !!Icones[name][size];
    const IconComponent = Icones[name][size] || Icones[name].default;
    if (!hasSize && autoResize) {
      const sizeNumber = Metrics.horizontalScale(+size);
      return (
        <IconComponent
          color={mapColor?.(color) || color}
          {...restProps}
          width={sizeNumber}
          height={sizeNumber}
        />
      );
    }
    return <IconComponent color={mapColor?.(color) || color} {...restProps} />;
  }
  return (
    <Text style={{color: mapColor?.(color) || color}}>Icon not found</Text>
  );
};

export default Icon;
