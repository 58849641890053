import {
  createGlobalStyle,
  css,
  FlattenSimpleInterpolation,
} from "styled-components";
import { responsiveMap } from "./_utils/responsiveObserve";

export const gridColumns = 12;
const percentage = (value: number) => value * 100 + "%";

export const $colPrefix = "ds-col";
export const $rowPrefix = "ds-row";

const GridGlobalCss = createGlobalStyle(() => {
  const loopGridColumns = (
    columns: number,
    cls: string
  ): FlattenSimpleInterpolation => {
    const result: string[] = [];

    for (let index = columns; index >= 0; index--) {
      if (index > 0) {
        result.push(`
              .${$colPrefix}${cls}-${index} {
                display: block;
                flex: 0 0 ${percentage(index / gridColumns)};
                max-width: ${percentage(index / gridColumns)};
              }
              .${$colPrefix}${cls}-push-${index} {
                left: ${percentage(index / gridColumns)};
              }
              .${$colPrefix}${cls}-pull-${index} {
                right: ${percentage(index / gridColumns)};
              }
              .${$colPrefix}${cls}-offset-${index} {
                margin-left: ${percentage(index / gridColumns)};
              }
              .${$colPrefix}${cls}-order-${index} {
                order: index;
              }
            `);
      } else if (index === 0) {
        result.push(`
              .${$colPrefix}${cls}-${index} {
                display: none;
              }
              .${$colPrefix}-push-${index} {
                left: auto;
              }
              .${$colPrefix}-pull-${index} {
                right: auto;
              }
              .${$colPrefix}${cls}-push-${index} {
                left: auto;
              }
              .${$colPrefix}${cls}-pull-${index} {
                right: auto;
              }
              .${$colPrefix}${cls}-offset-${index} {
                margin-left: 0;
              }
              .${$colPrefix}${cls}-order-${index} {
                order: 0;
              }
            `);
      }
    }
    return result;
  };
  const makeGrid = (breakpoint: string): FlattenSimpleInterpolation => {
    return loopGridColumns(gridColumns, breakpoint);
  };

  return css`
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }
    .${$colPrefix} {
      position: relative;
      max-width: 100%;
      /* // Prevent columns from collapsing when empty */
      min-height: 1px;
    }

    ${makeGrid("")}
    ${makeGrid("-xs")}
    
    @media ${responsiveMap.sm} {
      ${makeGrid("-sm")}
    }
    @media ${responsiveMap.md} {
      ${makeGrid("-md")}
    }
    @media ${responsiveMap.lg} {
      ${makeGrid("-lg")}
    }
    @media ${responsiveMap.xl} {
      ${makeGrid("-xl")}
    }
    @media ${responsiveMap.xxl} {
      ${makeGrid("-xxl")}
    }

    /* row */

    .${$rowPrefix} {
      display: flex;
      flex-flow: row wrap;
      min-width: 0;

      &::before,
      &::after {
        display: flex;
      }

      &-no-wrap {
        flex-wrap: nowrap;
      }
    }

    .${$rowPrefix}-start {
      justify-content: flex-start;
    }

    .${$rowPrefix}-center {
      justify-content: center;
    }

    .${$rowPrefix}-end {
      justify-content: flex-end;
    }

    .${$rowPrefix}-space-between {
      justify-content: space-between;
    }

    .${$rowPrefix}-space-around {
      justify-content: space-around;
    }

    .${$rowPrefix}-space-evenly {
      justify-content: space-evenly;
    }

    .${$rowPrefix}-top {
      align-items: flex-start;
    }

    .${$rowPrefix}-middle {
      align-items: center;
    }

    .${$rowPrefix}-bottom {
      align-items: flex-end;
    }
  `;
});

export default GridGlobalCss;
