import styled, {CSSProperties} from 'styled-components';
import {IDropdownProps} from './Dropdown';

export const _DropdownRoot = styled('div')<{}>(() => {
  return {
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
  };
});

export const _DropdownContentWrapper = styled('div')<{
  $placement: IDropdownProps['placement'];
}>(({$placement}) => {
  let additinalStyle: CSSProperties = {};

  if ($placement === 'topLeft') {
    additinalStyle = {
      bottom: 0,
      transform: 'translateY(-8px)',
    };
  } else if ($placement === 'bottomRight') {
    additinalStyle = {
      transform: 'translateX(-100%)',
    };
  }
  return {
    background: '#111216',
    color: 'black',
    boxShadow: '4px 4px 20px rgba(0, 0, 0, 0.25)',
    borderRadius: 24,
    position: 'absolute',
    transform: 'translateY(8px)',
    overflow: 'auto',
    padding: '22px 32px',
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
    width: 'max-content',
    ...additinalStyle,
  };
});
