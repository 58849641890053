import {combineReducers} from '@reduxjs/toolkit';
import {freeApi} from '@src/Api/FreeApi/freeApi';
import {traceApi} from '@src/Api/TraceApi/traceApi';
import {contentManagmentApi} from '@src/Api/Umbraco/ContentManagmentApi/contentManagmentApi';
import {umbracoCdnApi} from '@src/Api/Umbraco/ContentManagmentApi/UmbracoCdnApi/umbracoCdnApi';
import appSlice from './Slices/AppSlice';
import channelSlice from './Slices/Channel.slice';
import languageSlice from './Slices/LanguageSlice/Language.slice';
import layoutSlice from './Slices/LayoutSlice/Layout.slice';
import liveStreamSlice from './Slices/LiveStream.slice';
import vimeoSlice from './Slices/Vimeo/Vimeo.slice';

const rootReducer = combineReducers({
  [contentManagmentApi.reducerPath]: contentManagmentApi.reducer,
  [traceApi.reducerPath]: traceApi.reducer,
  [freeApi.reducerPath]: freeApi.reducer,
  [umbracoCdnApi.reducerPath]: umbracoCdnApi.reducer,
  [appSlice.name]: appSlice.reducer,
  [languageSlice.name]: languageSlice.reducer,
  [vimeoSlice.name]: vimeoSlice.reducer,
  [liveStreamSlice.name]: liveStreamSlice.reducer,
  [channelSlice.name]: channelSlice.reducer,
  [layoutSlice.name]: layoutSlice.reducer,
});

export default rootReducer;
