// import {FirebaseAuthTypes} from '@react-native-firebase/auth';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  IMemberDatasFragmentFragment,
  IMemberDownloadedFragmentFragment,
  IMemberMessageFragmentFragment,
  IMemberNotifyFragmentFragment,
  IMemberOpportunityFragmentFragment,
  IMemberSavedFragmentFragment,
  IMemberSearchFragmentFragment,
  IMemberWatchedFragmentFragment,
} from '~Api/Graphql/gql/types.generated';
import {Member} from '~Api/Umbraco/interface/Member.interface';

export type MemberDataSource = 'watch' | 'learn' | 'listen';

export interface IMemberSubscription {
  memberId?: string;
  subscriptionPrice?: string;
  subscriptionStart?: string;
  subscriptionEnd?: string;
  id: string;
  deviceType?: string;
  subscriptionType?: string;
  productId: string;
  transactionId: string;
}

interface MemberState {
  member?: Member;
  memberDatas?: IMemberDatasFragmentFragment;
  saved?: IMemberSavedFragmentFragment[];
  notify: IMemberNotifyFragmentFragment[];
  watched: IMemberWatchedFragmentFragment[];
  allWatched: Record<string, {parentId?: string; time: number; length: number}>;
  allSearch: IMemberSearchFragmentFragment[];
  messages?: IMemberMessageFragmentFragment[];
  opportunities: IMemberOpportunityFragmentFragment[];
  bookmarks: Record<MemberDataSource, IMemberSavedFragmentFragment[]>;
  downloaded: Record<MemberDataSource, IMemberDownloadedFragmentFragment[]>;
  memberSubscription?: IMemberSubscription;
  bookmarkCount: Record<string, number>;
  firebaseUser?: string;
}

const initialState = {
  member: undefined,
  opportunities: [],
  allSearch: [],
  notify: [],
  bookmarks: {
    learn: [],
    listen: [],
    watch: [],
  },
  downloaded: {
    learn: [],
    listen: [],
    watch: [],
  },
  watched: [],
  allWatched: {},
  memberSubscription: undefined,
  bookmarkCount: {},
} as MemberState;

// Then, handle actions in your reducers:
const memberSlice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    setFirebaseUser: (state, action: PayloadAction<string | undefined>) => {
      state.firebaseUser = action.payload;
      return state;
    },
    setMemberSubscription: (
      state,
      action: PayloadAction<IMemberSubscription | undefined>,
    ) => {
      state.memberSubscription = action.payload;
      return state;
    },
    setMemberSearch: (
      state,
      action: PayloadAction<IMemberSearchFragmentFragment[]>,
    ) => {
      state.allSearch = action.payload;
      return state;
    },
    setMember(state, action: PayloadAction<Member | undefined>) {
      state.member = action.payload;
      return state;
    },
    setMemberDatas(
      state,
      action: PayloadAction<IMemberDatasFragmentFragment | undefined>,
    ) {
      state.memberDatas = action.payload;
      return state;
    },

    setSaved(state, action: PayloadAction<IMemberSavedFragmentFragment[]>) {
      state.saved = action.payload;
      return state;
    },
    setNotify(state, action: PayloadAction<IMemberNotifyFragmentFragment[]>) {
      state.notify = action.payload;
      return state;
    },
    setAllWatched(
      state,
      action: PayloadAction<
        Record<string, {parentId?: string; time: number; length: number}>
      >,
    ) {
      state.allWatched = action.payload;
      return state;
    },
    setWatched(state, action: PayloadAction<IMemberWatchedFragmentFragment[]>) {
      state.watched = action.payload;
      return state;
    },
    setMessages(
      state,
      action: PayloadAction<IMemberMessageFragmentFragment[]>,
    ) {
      state.messages = action.payload;
      return state;
    },
    setOpportunities(
      state,
      action: PayloadAction<IMemberOpportunityFragmentFragment[]>,
    ) {
      state.opportunities = action.payload;
      return state;
    },
    setBookmarks(
      state,
      action: PayloadAction<{
        source: MemberDataSource;
        data: IMemberSavedFragmentFragment[];
      }>,
    ) {
      const {source, data} = action.payload;
      state.bookmarks[source] = data;
      return state;
    },
    setDownloaded(
      state,
      action: PayloadAction<{
        source: MemberDataSource;
        data: IMemberDownloadedFragmentFragment[];
      }>,
    ) {
      const {source, data} = action.payload;
      state.downloaded[source] = data;
      return state;
    },
    removeSourceItem(
      state,
      action: PayloadAction<{
        type: keyof Pick<MemberState, 'bookmarks' | 'downloaded'>;
        id: string;
        source: MemberDataSource | MemberDataSource[] | '*';
      }>,
    ) {
      const {type, id, source} = action.payload;
      const stateItem = state[type] || [];
      const allKeys = Object.keys(stateItem) as MemberDataSource[];
      const list: MemberDataSource[] =
        typeof source === 'string'
          ? source === '*'
            ? allKeys
            : [source]
          : source;

      list.forEach(sourceItem => {
        // @ts-ignore
        state[type][sourceItem] = state[type][sourceItem].filter(
          (item: any) => {
            return item && item.id ? item.id !== id : false;
          },
        );
      });
      return state;
    },
    updateSourceItem(
      state,
      action: PayloadAction<{
        type: 'bookmarks';
        id: string;
        source: MemberDataSource | MemberDataSource[] | '*';
        data: Partial<IMemberSavedFragmentFragment>;
      }>,
    ) {
      const {type, id, source, data} = action.payload;
      const stateItem = state[type] || [];
      const allKeys = Object.keys(stateItem) as MemberDataSource[];
      const list: MemberDataSource[] =
        typeof source === 'string'
          ? source === '*'
            ? allKeys
            : [source]
          : source;

      list.forEach(sourceItem => {
        // @ts-ignore
        state[type][sourceItem] = state[type][sourceItem].map((item: any) => {
          return item && item.id && item.id === id ? {...item, ...data} : item;
        });
      });

      return state;
    },
    updateItem(
      state,
      action: PayloadAction<{
        id: string;
        type: 'notify';
        data: Partial<IMemberNotifyFragmentFragment>;
      }>,
    ) {
      const {type, id, data} = action.payload;
      // @ts-ignore
      state[type] = state[type].map((item: any) => {
        return item && item.id && item.id === id ? {...item, ...data} : item;
      });

      return state;
    },
    addSourceItem(
      state,
      action: PayloadAction<
        | {
            type: 'bookmarks';
            source: MemberDataSource;
            data: IMemberSavedFragmentFragment;
          }
        | {
            type: 'downloaded';
            source: MemberDataSource;
            data: IMemberDownloadedFragmentFragment;
          }
      >,
    ) {
      const {type, data, source} = action.payload;
      switch (type) {
        case 'bookmarks':
          state[type][source].push(data);
          break;
        case 'downloaded':
          state[type][source].push(data);
          break;

        default:
          break;
      }

      return state;
    },
    addItem(
      state,
      action: PayloadAction<{
        type: 'notify';
        data: IMemberNotifyFragmentFragment;
      }>,
    ) {
      const {type, data} = action.payload;
      switch (type) {
        case 'notify':
          state[type].push(data);
          break;

        default:
          break;
      }

      return state;
    },
    removeItem(
      state,
      action: PayloadAction<{
        type: keyof Pick<
          MemberState,
          'messages' | 'notify' | 'saved' | 'watched'
        >;
        id: string;
      }>,
    ) {
      const {type, id} = action.payload;
      switch (type) {
        case 'messages':
          return {
            ...state,
            [type]: (state[type] || []).filter((item: any) => {
              return item && item.id !== id;
            }),
          };
        case 'notify':
          state.notify = state.notify.filter((item: any) => {
            return item && item.id !== id;
          });
          return state;
        case 'saved':
          return {
            ...state,
            [type]: (state[type] || []).filter((item: any) => {
              return item && item.id !== id;
            }),
          };
        case 'watched':
          return {
            ...state,
            [type]: (state[type] || []).filter((item: any) => {
              return item && item.id !== id;
            }),
          };

        default:
          return state;
      }
    },
    setBookmarkCount(
      state,
      action: PayloadAction<{contentId: string; count: number}>,
    ) {
      const {contentId, count} = action.payload;
      state.bookmarkCount[contentId] = count;
      return state;
    },
    addBookmarkCount(
      state,
      action: PayloadAction<{contentId: string; add: number}>,
    ) {
      const {contentId, add} = action.payload;
      state.bookmarkCount[contentId] = Math.max(
        (state.bookmarkCount[contentId] ?? 0) + add,
        0,
      );
      return state;
    },
  },
});

export default memberSlice;
