// import {track} from '@amplitude/analytics-react-native';
// import {IToggleBookmarkParams} from '~Hooks/useUpdateBookmarks';
import {track} from '@amplitude/analytics-browser';
import {isTrackingEnabled} from '@src/Providers/DidomiInitProvider/isTrackingEnabled';

const canSendEvent = async (): Promise<boolean> => {
  return true;
};

const mapContentType = (type: any) =>
  trackerTypenameMapper[type as any] || type;

export const trackerTypenameMapper: {
  [_ in any]?: string;
} = {
  News: 'Short',
};

export const tracker = {
  pageView: (properties: {path: string; title?: string}) => {
    isTrackingEnabled() &&
      track({
        event_type: 'Page opened',
        event_properties: {
          'page name': properties.path,
        },
      });
  },
  general: {
    async cTAClicked(properties: {
      type: string;
      name: string;
      section?:
        | 'watch'
        | 'learn'
        | 'listen'
        | 'subscription'
        | 'profile'
        | 'onboarding'
        | string;
    }) {
      if (await canSendEvent()) {
        track('CTA clicked', {
          cta_type: mapContentType(properties.type),
          cta_name: properties.name,
          cta_section: properties.section,
        });
      }
    },
  },
};
