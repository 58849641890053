import Metrics from '../../../../Theme/Metrics';

export const barLoaderSizeStyles = {
  medium: {
    width: Metrics.horizontalScale(142),
    height: Metrics.verticalScale(22),
  },
  large: {
    width: Metrics.horizontalScale(232),
    height: Metrics.verticalScale(32),
  },
  small: {
    width: Metrics.horizontalScale(96),
    height: Metrics.verticalScale(22),
  },
  button: {
    width: Metrics.horizontalScale(120),
    height: Metrics.verticalScale(32),
  },
  xsmall: {
    width: Metrics.horizontalScale(58),
    height: Metrics.verticalScale(22),
  },
  xxsmall: {
    width: Metrics.horizontalScale(40),
    height: Metrics.verticalScale(22),
  },
};
