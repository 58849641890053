import {UmbracoContentManagmentEndpointBuilder} from '../contentManagmentApi';

export const createMediaEndpoint = (
  builder: UmbracoContentManagmentEndpointBuilder,
) => ({
  getMedia: builder.query<any, string>({
    query: id => ({
      url: `/media${id}`,
      method: 'GET',
      apiVersion: '2',
    }),
  }),
  createMedia: builder.query<any, {body: FormData}>({
    query: ({body}) => ({
      url: '/media',
      method: 'POST',
      apiVersion: '2',
      body,
      contentType:
        'Content-Type:multipart/form-data; boundary=MultipartBoundry',
    }),
  }),
  updateMedia: builder.query<any, {id: string; body: FormData}>({
    query: ({id, body}) => ({
      url: `/media/${id}`,
      method: 'PUT',
      apiVersion: '2',
      body,
      contentType:
        'Content-Type: multipart/form-data; boundary=MultipartBoundry',
    }),
  }),
  deleteMedia: builder.query<any, string>({
    query: id => ({
      url: `/media/${id}`,
      method: 'DELETE',
      apiVersion: '2',
    }),
  }),
});
