import React, { ElementType } from 'react';
import { ITypographyProps, typographyGetColor } from './Typography.props';
import { useThemeContext } from '../../Provider/ThemeProvider/ThemeContext';
import { styled } from 'styled-components';
import {
  typographySharedStyle,
  typographyVariantStyled,
} from './Typography.styles';

const _Text = styled('span')<{ $variant: ITypographyProps['variant'], $color: ITypographyProps['color'] }>(({ $variant = 'h1', $color = 'white' }) => {
  const variantStyles = typographyVariantStyled[$variant];

  return {
    ...variantStyles,
    color: $color,
    ...typographySharedStyle.font,
    lineHeight: variantStyles.lineHeight ? variantStyles.lineHeight + 'px' : undefined,
  }
})


const Typography = (props: ITypographyProps & { as?: ElementType }) => {
  const { children, variant = 'h1', style, color = 'white', ...rest } = props;
  const {component} = useThemeContext();
  const { mapColor } = component?.typography || {}
  const textColor= mapColor?.(typographyGetColor(color)) || typographyGetColor(color)

  return (
    <_Text
      $variant={variant}
      $color={textColor}
      style={typeof style === 'object' ? { ...style, lineHeight: style.lineHeight ? style.lineHeight + 'px' : undefined } : undefined}
      {...rest}>
      {children}
    </_Text>
  );
};

export default Typography;
