import React from 'react';
import {StyleSheet, View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import BarLoader from '../ComponentLoader/BarLoader/BarLoader';
import CardLoader from '../ComponentLoader/CardLoader/CardLoader';

const CarouselLoader = () => {
  return (
    <View style={carouselLoaderStyles.root}>
      <BarLoader
        type="medium"
        style={{
          marginLeft: Metrics.horizontalScale(16),
          marginBottom: Metrics.verticalScale(8),
        }}
      />
      <View style={carouselLoaderStyles.container}>
        <CardLoader type="small" />
        <CardLoader type="small" />
      </View>
    </View>
  );
};

export default CarouselLoader;

const carouselLoaderStyles = StyleSheet.create({
  root: {
    marginVertical: Metrics.verticalScale(23),
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: Metrics.horizontalScale(16),
  },
});
