import {AxiosRequestConfig, AxiosError} from 'axios';
import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import {umbracoCdnAxiosInstance} from './umbracoCdn.axios';

export const umbracoCdnBaseQueryFn: BaseQueryFn<
  {
    url: string;
    baseUrl?: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    apiVersion?: string;
    contentType?: string;
  },
  unknown,
  unknown,
  {}
> = async ({
  url,
  method,
  body: data,
  params,
  apiVersion = '2',
  contentType,
}) => {
  try {
    const result = await umbracoCdnAxiosInstance({
      url,
      method,
      data,
      params,
      headers: {
        'Api-Version': apiVersion,
        'Content-Type': contentType,
      },
    });
    return {
      data: result?.data,
      error: undefined,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {status: err.response?.status, data: err.response?.data},
      data: undefined,
    };
  }
};
