import {ApolloClient, NormalizedCacheObject} from '@apollo/client';
import {useEffect, useState} from 'react';
import {initApolloInstance} from '~Api/Graphql';

export const useApiClient = () => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  useEffect(() => {
    initApolloInstance().then(_client => {
      if (_client) {
        setClient(_client);
      }
    });
  }, []);
  return {client};
};
