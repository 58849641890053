import { ReactNode } from "react";
import { TextProps, StyleProp, TextStyle } from "react-native";
import defaultTheme from "../../Theme/defaultTheme";
import { typographyVariantStyled } from "./Typography.styles";

export const typographyColor = defaultTheme.typography.color;

export interface ITypographyProps extends Omit<TextProps, 'style'> {
  children: ReactNode;
  variant?: keyof typeof typographyVariantStyled;
  color?: keyof typeof typographyColor | (() => string);
  style?: StyleProp<TextStyle>;
}

export const typographyGetColor = (
  color: NonNullable<ITypographyProps['color']>,
): string =>
  typeof color === 'function' ? color() : typographyColor[color] ?? color;
