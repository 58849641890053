import React from 'react';
import {ScrollView, View, Pressable, StyleSheet} from 'react-native';
import Metrics, {isWeb, width} from '../../Theme/Metrics';

export type GridViewProps<T> = {
  data: T[];
  onItemPress?: (item: T) => void;
  renderItem: (item: T, index?: number) => React.ReactNode;
  footer?: React.ReactNode;
  yPadding?: number;
  xPaddingWrap?: number;
};

const GridView = <T extends any>(props: GridViewProps<T>) => {
  const {
    data,
    onItemPress,
    renderItem,
    yPadding = 24,
    footer,
    xPaddingWrap = 16,
  } = props;
  return (
    <ScrollView
      style={{marginBottom: yPadding * 2}}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        flexGrow: 1,
        paddingHorizontal: Metrics.horizontalScale(xPaddingWrap - 4),
        marginTop: -yPadding / 2,
      }}>
      <View style={styles.container}>
        {data.map((value, index) => {
          let item = value as any;
          return (
            <Pressable
              key={index}
              style={[
                {
                  paddingVertical: Metrics.verticalScale(yPadding / 2),
                },
                styles.item,
              ]}
              onPress={
                onItemPress &&
                (() => {
                  onItemPress(item);
                })
              }>
              {renderItem(item, index)}
            </Pressable>
          );
        })}
      </View>
      {footer}
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    width: isWeb ? 375 : Metrics.horizontalScale(width),
    flexDirection: 'row',
    flexWrap: 'wrap',
    // justifyContent: 'center',
  },
  item: {
    marginHorizontal: Metrics.horizontalScale(4),
  },
});

export default GridView;
