import React from 'react';
import {View} from 'react-native';
import Metrics from '../../../Theme/Metrics';
import CarouselLoader from './CarouselLoader';
import CustomHeaderLoader from './CustomHeaderLoader';

const HomeContentLoader = () => {
  return (
    <View>
      <CustomHeaderLoader />
      <View
        style={{
          marginTop: Metrics.verticalScale(70),
        }}>
        <CarouselLoader />
        <CarouselLoader />
      </View>
    </View>
  );
};

export default HomeContentLoader;
