import {FreeApiEndpointBuilder} from '../freeApi';
// import convert from 'xml-js';
import {parseString} from 'xml2js';
import {TvGuidResponse} from '../interface/tvGuide';
import {ITvGuideItem, TvModel} from '~Models/TvModel';
import channelSlice from '~Store/Slices/Channel.slice';

export const createXmlEndpoint = (builder: FreeApiEndpointBuilder) => ({
  getTvGuideXml: builder.query<
    ITvGuideItem[],
    {channelId: string; url: string}
  >({
    query: ({url}) => ({
      url: url,
      method: 'GET',
    }),
    transformResponse(response) {
      return new Promise((resolve, reject) => {
        if (typeof response === 'string') {
          parseString(response, (err: any, json: TvGuidResponse) => {
            if (!err) {
              if (json.tv) {
                resolve(new TvModel(json).getGuide());
              }
            }
          });
        }
        reject({});
      });
    },

    async onQueryStarted({channelId}, {dispatch, queryFulfilled}) {
      try {
        const response = await queryFulfilled;
        if (response && response.data) {
          dispatch(
            channelSlice.actions.setChannelGuide({
              id: channelId,
              data: response.data,
            }),
          );
        }
      } catch (error) {
        console.log('getTvGuideXml--', error);
      }
    },
  }),
});
