import styled from 'styled-components';
import {IAwardCategoryLayoutProps} from './AwardLayout';
// @ts-ignore
import awardBackground from '../../images/award-category-bg-min.png';

export const _AwardLayoutRoot = styled('div')<{
  $variant?: IAwardCategoryLayoutProps['variant'];
}>(({$variant}) => {
  return {
    background:
      $variant === 'category' ? `url(${awardBackground})` : 'transparent',
    backgroundSize: '100% auto',
  };
});
