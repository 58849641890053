import {IThankYouPageFragmentFragment} from '~Api/Graphql/gql/types.generated';
import mapButton, { IMapButtonResult } from '~Umbraco/Utils/element/mapButton';

export type IThankYouPageData = IThankYouPageFragmentFragment & {
  coverUrl?: string;
  coverUrlDesktop?: string;
  buttonData?: IMapButtonResult;
};

const useThankYouPageMapper = () => {
  const mapThankYouPage = (
    data: IThankYouPageFragmentFragment,
  ): IThankYouPageData => {
    const {cover, cTA} = data;

    return {
      ...data,
      coverUrl:
        cover && cover[0] && cover[0].content.__typename === 'HeroVideoCover'
          ? cover[0].content.image?.url || ''
          : '',
      coverUrlDesktop: cover && cover[0] && cover[0].content.__typename === 'HeroVideoCover'
      ? cover[0].content.desktopImage?.url || ''
      : '',
      buttonData:
        cTA &&
        cTA[0] &&
        cTA[0].content &&
        cTA[0].content.__typename === 'Button'
          ? mapButton(cTA[0].content)
          : undefined,
    };
  };
  return {
    mapThankYouPage,
  };
};

export default useThankYouPageMapper;
