import {isRestricted} from './isRestricted';
// import {store} from '~Store/store';

export const isElementRestricted = (element: any): boolean => {
  // check country restriction
  const isCountryRestricted =
    element &&
    typeof element.countriesRestriction !== 'undefined' &&
    Array.isArray(element.countriesRestriction) &&
    !!element.countriesRestriction.length
      ? isRestricted(
          element.countriesRestriction.map((v: any) => {
            const [, country] = (v || '').split('|');
            return country ? country.trim() : '';
          }),
        )
      : false;

  // TODO: get member from store
  // const {
  //   member: {member},
  // } = store.getState();
  const member: any = undefined;

  // Check region restriction
  const isRegionRestricted =
    element &&
    !!element.availableRegions &&
    Array.isArray(element.availableRegions) &&
    !!element.availableRegions[0] &&
    !!element.availableRegions[0].id &&
    member &&
    member.region
      ? member.region !==
        `umb://document/${(element.availableRegions[0].id + '')
          .split('-')
          .join('')}`
      : false;

  // return isCountryRestricted || isRegionRestricted;
  return false;
};
