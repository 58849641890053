import React from 'react';
import {
  ActivityIndicator,
  ActivityIndicatorProps,
  ColorValue,
} from 'react-native';
import defaultTheme from '../../Theme/defaultTheme';

interface CustomActivityIndicatorProps
  extends Omit<ActivityIndicatorProps, 'color'> {
  customColor?: ColorValue;
}

const CustomActivityIndicator = (props: CustomActivityIndicatorProps) => {
  let {customColor} = props;
  return (
    <ActivityIndicator color={customColor ?? defaultTheme.primary} {...props} />
  );
};

export default CustomActivityIndicator;
