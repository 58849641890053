import React, {createContext} from 'react';
import {IButtonProps} from '../../Components/Button/Button';
import defaultTheme, {Theme} from '../../Theme/defaultTheme';

export interface ThemeState extends Theme {
  component?: {
    button: {
      [_variant in NonNullable<IButtonProps['variant']>]: Pick<
        IButtonProps,
        'customStyle'
      >;
    };
    typography?: {
      mapColor?: (currentColor: string) => string;
    };
    textInput?: {
      mapColor?: (currentColor?: string) => string | undefined;
    };
  };
}

const ThemeContext = createContext<ThemeState>({
  ...defaultTheme,
});

const ThemeContextProvider = ThemeContext.Provider;

const useThemeContext = () => {
  return React.useContext<ThemeState>(ThemeContext);
};

export {ThemeContextProvider, useThemeContext};
export default ThemeContext;
