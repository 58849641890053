import {Dimensions, PixelRatio, Platform} from 'react-native';

export const isWeb: boolean = Platform.OS === 'web';

const window = Dimensions.get('window');
export const {width, height} = window;
const getWidth = () => (isWeb ? window.width : Math.min(width, height));
const getHeight = () => (isWeb ? window.height : Math.max(width, height));

export const guidelineBaseWidth = 375;
export const guidelineBaseHeight = 812;

export const horizontalScale = (size: number) =>
  isWeb ? size : (getWidth() / guidelineBaseWidth) * size;

export const verticalScale = (size: number) =>
  isWeb ? size : (getWidth() / guidelineBaseWidth) * size;

export const moderateScale = (size: number, factor = 0.5) =>
  isWeb ? size : size + (horizontalScale(size) - size) * factor;

export const fontScale = (size: number) =>
  isWeb ? size : size / PixelRatio.getFontScale();

const Metrics = {
  horizontalScale,
  verticalScale,
  moderateScale,
  fontScale,
  window: {
    ...window,
    width: getWidth(),
    height: getHeight(),
  },
};
export default Metrics;
