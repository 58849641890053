import {Member} from '~Api/Umbraco/interface/Member.interface';
import {IMemberDto} from '../interface/umbraco.interface';
import {TraceApiEndpointBuilder} from '../traceApi';

export const createAuthEndpoint = (builder: TraceApiEndpointBuilder) => ({
  traceSignUp: builder.query<{token: string; member: Member}, IMemberDto>({
    query: body => ({
      url: '/auth/signup',
      method: 'POST',
      body,
    }),
  }),
});
