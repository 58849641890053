import {
  PageProps,
  WrapPageElementBrowserArgs,
  WrapPageElementNodeArgs,
} from 'gatsby';
import React, {useEffect, useMemo, useRef} from 'react';
import ModalRoutingContext from '../plugins/layout-wrapper/ModalRoutingContext';
import Modal from './components/general/PageModal/PageModal';
import Home from './components/page-template/Home/Home';
import AwardLayout from './layout/AwardLayout/AwardLayout';
import useChangeLanguage from '@src/Hooks/useChangeLanguage';
import useTracker from './Services/Amplitude/hooks/useTracker';
import {languageUnmap} from './Services/i18n/languageMapper';
import TranslationLoader from './Providers/TranslationLoader/TranslationLoader';

// overide layout
const DefaultLayout = AwardLayout;

export type ILayoutProviderProps = {
  element: WrapPageElementBrowserArgs['element'];
  props:
    | WrapPageElementBrowserArgs<
        any,
        any,
        {modal?: boolean} & Record<string, any>
      >['props']
    | WrapPageElementNodeArgs<any>['props'];
  locationState?: WrapPageElementBrowserArgs<
    any,
    any,
    {modal?: boolean} & Record<string, any>
  >['props']['location']['state'];
};

export type InnerLayoutProps = {
  context?: Record<string, unknown>;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  location?: PageProps['location'];
  isModal?: boolean;
};

const getElementLayout = (
  element?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
) => {
  if (!element) {
    return null;
  }
  return typeof element.type !== 'string' &&
    // @ts-ignore
    element.type.layoutComponent
    ? // @ts-ignore
      element.type.layoutComponent
    : DefaultLayout;
};

const getElementUri = (
  element?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
) => {
  const uri: string = element?.props?.uri + '';
  if (!uri) {
    return '/';
  }
  const {
    pageContext: {language},
  } = element?.props || {};
  if (language) {
    const regex = new RegExp(`\/?${language}(\/.*)`);
    const tag = regex.exec(uri);

    if (tag && tag[1]) {
      console.log('tag', tag[1]);
      return tag[1];
    }
  }
  return uri;
};

const getElementLayoutProps = (
  element?: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
) => {
  // @ts-ignore
  return element?.type?.layoutProps ? {...element.type.layoutProps} : {};
};

const LayoutProvider = (props: ILayoutProviderProps) => {
  const {element, props: wrapperProps} = props;
  const {updateStoreLang, currentLanguage} = useChangeLanguage();
  const tracker = useTracker();

  // Init product data
  const PreviousLayout = useRef<React.ComponentType<InnerLayoutProps>>();
  const PreviousElement =
    useRef<
      React.ReactElement<any, string | React.JSXElementConstructor<any>>
    >();

  const {pageContext, data, location, params} = wrapperProps;
  const locationState: {modal?: boolean; closeTo?: string} = useMemo(
    () =>
      (location.state as WrapPageElementBrowserArgs<
        any,
        any,
        {modal?: boolean} & Record<string, any>
      >['props']['location']['state']) || {},
    [location.state],
  );
  const isModal: boolean = useMemo(
    () =>
      // @ts-ignore
      !!(locationState && locationState.modal) ||
      // @ts-ignore
      element.type.isModal === true,
    [
      // @ts-ignore
      (element.type.isModal, locationState),
    ],
  );
  const closeTo: string = useMemo(
    () =>
      PreviousElement.current
        ? getElementUri(PreviousElement.current)
        : (locationState && locationState.closeTo) || '/',
    [locationState],
  );

  // ParentContent
  const ParentContent: React.ComponentType = useMemo(
    () =>
      typeof element.type !== 'string' &&
      // @ts-ignore
      element.type.ParentContent
        ? // @ts-ignore
          element.type.ParentContent
        : Home,
    [element.type],
  );

  // layout props
  const layoutProps: InnerLayoutProps = useMemo(
    () => ({
      // context: pageContext,
      // data: data,
      // location: location,
      // params: params,
      // isModal: isModal,
      ...getElementLayoutProps(
        // @ts-ignore
        isModal ? PreviousElement.current : element.type.layoutProps,
      ),
    }),
    [data, isModal, location, pageContext, params],
  );

  /*  */
  const PageElement = useMemo(
    () =>
      isModal
        ? PreviousElement.current ||
          // @ts-ignore
          React.createElement(ParentContent, {...layoutProps})
        : element,
    [ParentContent, element, isModal, layoutProps],
  );

  // Rendering the current page as a modal, so create an element with the page contents
  const modalElement = isModal ? element : null;

  const handleModalClose = () => {
    // navigate(closeTo);
  };

  useEffect(() => {
    const elUri = getElementUri(element);
    tracker.pageView({
      path: elUri,
    });
  }, [element]);

  useEffect(() => {
    if (!isModal) {
      PreviousLayout.current = getElementLayout(element);
      PreviousElement.current = element;
    }
  }, [element, isModal]);

  useEffect(() => {
    if (currentLanguage !== pageContext.language) {
      updateStoreLang(languageUnmap(pageContext.language));
    }
  }, [updateStoreLang, currentLanguage, pageContext.language]);

  // Layout component
  const Layout: React.ComponentType<InnerLayoutProps> = useMemo(
    () =>
      !isModal
        ? getElementLayout(element)
        : PreviousLayout.current || getElementLayout(element) || DefaultLayout,
    [element, isModal],
  );

  const ModalLayout = useMemo(() => {
    return isModal ? getElementLayout(element) : undefined;
  }, [isModal, element]);

  return (
    <div className="layout-root">
      <TranslationLoader language={pageContext.language} />
      <Layout {...layoutProps}>{PageElement}</Layout>
      <Modal
        show={isModal}
        onClose={handleModalClose}
        // @ts-ignore
        {...element.modalProps}>
        <ModalRoutingContext.Provider
          value={{
            modal: true,
            closeTo,
          }}>
          {ModalLayout ? (
            <ModalLayout {...layoutProps} variant="modal">
              {modalElement}
            </ModalLayout>
          ) : (
            modalElement
          )}
        </ModalRoutingContext.Provider>
      </Modal>
      {isModal && (
        <style
          id="body-override"
          dangerouslySetInnerHTML={{
            __html: `
              body {
                overflow: hidden;             
              }`,
          }}></style>
      )}
    </div>
  );
};

export default LayoutProvider;
