import {AxiosRequestConfig, AxiosError} from 'axios';
import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import {contentManagementInstance} from './contentManagement.axios';
import apiConfig from '~Configs/apiConfig';
import {Platform} from 'react-native';

export const baseQueryFn: BaseQueryFn<
  {
    url: string;
    baseUrl?: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    apiVersion?: string;
    contentType?: string;
  },
  unknown,
  unknown,
  {}
> = async ({
  url,
  method,
  body: data,
  params,
  apiVersion = '2',
  contentType,
}) => {
  try {
    if (url === '/media' && method === 'POST' && Platform.OS === 'android') {
      const result = await fetch(apiConfig.contentManagmentUrl + url, {
        method: 'POST',
        body: data,
        headers: {
          'Umb-Project-Alias': apiConfig.umbProjectAlias,
          'Api-Key': apiConfig.umbApiKey,
          'Api-Version': apiVersion,
          'Content-Type': 'multipart/form-data',
        },
      });
      const res = await result.json();
      return {
        data: res,
        error: undefined,
      };
    } else {
      const result = await contentManagementInstance({
        url: apiConfig.contentManagmentUrl + url,
        method,
        data,
        params,
        headers: {
          'Api-Version': apiVersion,
          'Content-Type': contentType,
        },
      });
      return {
        data: result?.data,
        error: undefined,
      };
    }
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {status: err.response?.status, data: err.response?.data},
      data: undefined,
    };
  }
};
