import {useApolloClient} from '@apollo/client';
import {useCallback} from 'react';

// import useInitSetting from '~Services/Query/hooks/useInitSetting';
import {useCurrentLanguage} from '~Store/Slices/LanguageSlice/Language.selector';
import languageSlice from '~Store/Slices/LanguageSlice/Language.slice';
// import useSetupRegion from './useSetupRegion';
import {useAppDispatch} from '@src/Store/hooks';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {languageMapper} from '@src/Services/i18n/languageMapper';

const delay = (timer: number) =>
  new Promise(resolve => {
    setTimeout(resolve, timer);
  });

const useChangeLanguage = () => {
  const client = useApolloClient();
  // const {init} = useInitSetting();
  const currentLanguage = useCurrentLanguage();
  const {changeLanguage} = useI18next();
  const dispatch = useAppDispatch();
  // const {fetch: fetchRegion} = useSetupRegion(true);

  const updateApi = useCallback(
    async (lang: string) => {
      // await changeLanguage(lang);
      await client.resetStore();
      // await init();
      // await fetchRegion();
    },
    [client, currentLanguage],
  );

  const _changeLanguage = useCallback(
    async (language: string) => {
      await changeLanguage(languageMapper(language));
    },
    [dispatch, updateApi],
  );

  const updateStoreLang = useCallback(
    async (language: string) => {
      dispatch(languageSlice.actions.setCurrentLanguage(language));
      await delay(1000);
      await updateApi(language);
    },
    [updateApi],
  );
  return {
    currentLanguage,
    updateStoreLang,
    changeLanguage: _changeLanguage,
  };
};

export default useChangeLanguage;
