import {CustomActivityIndicator} from 'design-system/src/Components/CustomActivityIndicator';
import React from 'react';
import {View} from 'react-native';

const PageLoader = () => {
  return (
    <div>
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
        }}>
        <CustomActivityIndicator />
      </View>
    </div>
  );
};

export default PageLoader;
