import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {IVimeoVideo} from '~Api/TraceApi/interface/vimeoVideo.interface';

interface VimeoState {
  videos: Record<string, IVimeoVideo>;
}

const initialState = {
  videos: {},
} as VimeoState;

// Then, handle actions in your reducers:
const vimeoSlice = createSlice({
  name: 'vimeo',
  initialState,
  reducers: {
    addVideosData(
      state,
      action: PayloadAction<{id: string; video: IVimeoVideo}>,
    ) {
      const {id, video} = action.payload;
      state.videos[id] = video;
      return state;
    },
  },
});

export default vimeoSlice;
