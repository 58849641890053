import {useI18next} from 'gatsby-plugin-react-i18next';
import React, {useMemo} from 'react';
import {NavigateOptions} from '@reach/router';
import {useStaticQuery, graphql} from 'gatsby';

const useNavigation = () => {
  const navigation = useI18next();
  const {allAwardPageRedirection} =
    useStaticQuery<Queries.AllAwardsPageRedirectionQuery>(graphql`
      query AllAwardsPageRedirection {
        allAwardPageRedirection {
          nodes {
            id
            fromPath
            toPath
          }
        }
      }
    `);
  const list: Record<string, string> = useMemo(() => {
    const _list: Record<string, string> = {};
    if (allAwardPageRedirection && allAwardPageRedirection.nodes) {
      allAwardPageRedirection.nodes.forEach(item => {
        if (item.fromPath && item.toPath) {
          _list[item.fromPath] = item.toPath;
        }
      });
    }
    return _list;
  }, [allAwardPageRedirection]);
  const navigate: (
    to: string,
    options?: NavigateOptions<{}> | undefined,
  ) => Promise<void> = async (to, options) => {
    navigation.navigate(list[to] || to, options);
  };
  return {...navigation, navigate};
};

export default useNavigation;
