import {DidomiSDK} from '@didomi/react';
import apiConfig from '@src/Configs/apiConfig';
import React, {useRef} from 'react';

const DidomiInitProvider = () => {
  // const DidomiRef = useRef<DidomiReact.IDidomiObject | null>();
  //   const [didomiPreferences, setDidomiPreferences] = useState<
  //     Record<string, boolean>
  //   >({});

  //   useEffect(() => {
  //     if (DidomiRef.current) {
  //       console.log('didomi config', DidomiRef.current.getConfig());
  //     }
  //   }, [DidomiRef.current]);

  //   const handleSave = () => {
  //     console.log('save', didomiPreferences);
  //   };

  return (
    <DidomiSDK
      apiKey={apiConfig.didomiApiKey}
      iabVersion={2} // If you want to support the TCF v1∏, don't forget to change this value, even if you selected the TCF v2 in the console. This parameter will load the correct stub in the React Component
      //   noticeId={apiConfig.didomiNoticeId} // If you want to target the notice by ID and not by domain
      gdprAppliesGlobally={true}
      sdkPath="https://sdk.privacy-center.org/"
      embedTCFStub={true}
      onReady={didomi => {
        // DidomiRef.current = didomi;
        // console.log('Didomi SDK is loaded and ready', didomi);
        // console.log(
        //   'Didomi Ready - Is the consent required ? : ',
        //   didomi.isConsentRequired(),
        // );
        // console.log(
        //   'Didomi Ready - Do we have the consent for the vendor IAB 1 : ',
        //   didomi.getUserConsentStatusForVendor(1),
        // );
        // console.log(
        //   'Didomi Ready - Do we have the consent for the vendor IAB 1 and the purpose cookies : ',
        //   didomi.getUserConsentStatus('cookies', 1),
        // );
        // console.log('didomi -debug', didomi.notice.show());
        // if (didomi) {
        //   const {purposes} = didomi.getUserConsentStatusForAll();
        //   if (!purposes.enabled.length) {
        //     didomi.preferences.show();
        //   }
        // }
        // if (didomi.policy) {
        //   didomi.policy.open();
        // }
        // console.log('isVisible', didomi.preferences.isVisible());
      }}
      //   onConsentChanged={cwtToken =>
      //     console.log('A consent has been given/withdrawn', cwtToken)
      //   }
      //   onNoticeShown={() => console.log('Didomi Notice Shown')}
      //   onNoticeHidden={() => console.log('Didomi Notice Hidden')}
      //   onNoticeBackdropclick={() => {
      //     console.log('Didomi Notice Backdrop Click');
      //     setDidomiPreferences({});
      //   }}
      //   onNoticeClickAgree={() => {
      //     console.log('Didomi Notice Click Agree');
      //     setDidomiPreferences({
      //       [apiConfig.techCookiePurpId]: true,
      //       [apiConfig.geolocPurpId]: true,
      //       [apiConfig.appsFlyerPurpId]: true,
      //       [apiConfig.amplitudePurpId]: true,
      //       [apiConfig.mcContentPurpId]: true,
      //       [apiConfig.mcStandardAdsPurpId]: true,
      //       [apiConfig.mcPersonalisedPurpId]: true,
      //     });
      //   }}
      //   onNoticeClickMoreInfo={() => console.log('Didomi Notice Click More Info')}
      //   onPreferencesClickAgreeToAll={() => {
      //     console.log('Didomi Preferences Click Agree to All');
      //     setDidomiPreferences({
      //       [apiConfig.techCookiePurpId]: true,
      //       [apiConfig.geolocPurpId]: true,
      //       [apiConfig.appsFlyerPurpId]: true,
      //       [apiConfig.amplitudePurpId]: true,
      //       [apiConfig.mcContentPurpId]: true,
      //       [apiConfig.mcStandardAdsPurpId]: true,
      //       [apiConfig.mcPersonalisedPurpId]: true,
      //     });
      //   }}
      //   onPreferencesClickDisagreeToAll={() => {
      //     console.log('Didomi Preferences Click Disagree to All');
      //     setDidomiPreferences({});
      //   }}
      //   onPreferencesClickPurposeAgree={purposeId => {
      //     console.log('Didomi Preferences Click Purpose Agree', purposeId);
      //     setDidomiPreferences(c => ({...c, [purposeId]: true}));
      //   }}
      //   onPreferencesClickPurposeDisagree={purposeId => {
      //     console.log('Didomi Preferences Click Purpose Disagree', purposeId);
      //     setDidomiPreferences(c => ({...c, [purposeId]: false}));
      //   }}
      //   onPreferencesClickViewVendors={() =>
      //     console.log('Didomi Preferences Click View Vendors')
      //   }
      //   onPreferencesClickSaveChoices={() => {
      //     console.log('Didomi Preferences Click Save Choices');
      //     handleSave();
      //   }}
      //   onPreferencesClickVendorAgree={vendorId => {
      //     console.log('Didomi Preferences Click Vendor Agree', vendorId);
      //     setDidomiPreferences(c => ({...c, [vendorId]: true}));
      //   }}
      //   onPreferencesClickVendorDisagree={vendorId => {
      //     console.log('Didomi Preferences Click Vendor Disagree', vendorId);
      //     setDidomiPreferences(c => ({...c, [vendorId]: false}));
      //   }}
      //   onPreferencesClickVendorSaveChoices={() => {
      //     console.log('Didomi Preferences Click Vendor Save Choices');
      //     handleSave();
      //   }}
    />
  );
};

export default DidomiInitProvider;
