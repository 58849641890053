import React, {createContext} from 'react';

export interface VimeoState {
  getVimeoVideo(vimeoId: string): Promise<
    | {
        uri: string;
        poster?: string;
      }
    | undefined
  >;
}

const VimeoContext = createContext<VimeoState>({
  getVimeoVideo: id =>
    Promise.resolve({
      uri: id,
    }),
});

const VimeoContextProvider = VimeoContext.Provider;

const useVimeoContext = () => {
  return React.useContext<VimeoState>(VimeoContext);
};

export {VimeoContextProvider, useVimeoContext};
export default VimeoContext;
