import {StyleSheet} from 'react-native';
import Metrics from '../../Theme/Metrics';

export enum EFontFamily {
  REGULAR = 'SuisseIntl-Regular',
  SEMI_BOLD = 'SuisseIntl-SemiBold',
  CONDENSED = 'SuisseIntlCond',
  NOW_VAR = 'HelveticaNowVar',
}

export const typographySharedStyle = StyleSheet.create({
  font: {},
});

export const typographyVariantStyled = StyleSheet.create({
  htitle: {
    fontFamily: EFontFamily.NOW_VAR,
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: Metrics.fontScale(14),
    letterSpacing: Metrics.fontScale(0.1),
    textTransform: 'uppercase',
  },
  h1: {
    //
    fontFamily: EFontFamily.CONDENSED,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: Metrics.fontScale(32),
    lineHeight: Metrics.fontScale(36),
    letterSpacing: Metrics.fontScale(-0.006),
    textTransform: 'uppercase',
  },
  h2: {
    //
    fontFamily: EFontFamily.CONDENSED,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: Metrics.fontScale(24),
    lineHeight: Metrics.fontScale(25),
    letterSpacing: Metrics.fontScale(0.5),
    textTransform: 'uppercase',
  },
  h3: {
    //
    fontFamily: EFontFamily.SEMI_BOLD,
    fontWeight: '600', // semi-bold
    fontSize: Metrics.fontScale(18),
    lineHeight: Metrics.fontScale(20),
    letterSpacing: Metrics.fontScale(0.46),
  },
  h4: {
    //
    fontFamily: EFontFamily.SEMI_BOLD,
    fontWeight: '600', // semi-bold
    fontSize: Metrics.fontScale(14),
    lineHeight: Metrics.fontScale(16),
    textTransform: 'uppercase',
    letterSpacing: Metrics.fontScale(0.5),
  },
  //   body
  body1: {
    //
    fontFamily: EFontFamily.REGULAR,
    fontSize: Metrics.fontScale(18),
    lineHeight: Metrics.fontScale(24),
  },
  body2: {
    //
    fontFamily: EFontFamily.REGULAR,
    fontSize: Metrics.fontScale(16),
    lineHeight: Metrics.fontScale(21),
    letterSpacing: Metrics.fontScale(0.29),
  },
  body3: {
    //
    fontFamily: EFontFamily.REGULAR,
    fontSize: Metrics.fontScale(14),
    lineHeight: Metrics.fontScale(18),
    letterSpacing: Metrics.fontScale(0.29),
  },
  //   button
  button1: {
    //
    fontFamily: EFontFamily.SEMI_BOLD,
    fontWeight: '600', // semi-bold
    fontSize: Metrics.fontScale(12),
    lineHeight: Metrics.fontScale(13),
    letterSpacing: Metrics.fontScale(0.38),
    textTransform: 'uppercase',
  },
  button2: {
    fontFamily: EFontFamily.REGULAR,
    fontSize: Metrics.fontScale(16),
    lineHeight: Metrics.fontScale(21),
  },
  button3: {
    fontFamily: EFontFamily.REGULAR,
    fontSize: Metrics.fontScale(14),
    lineHeight: Metrics.fontScale(18),
    letterSpacing: 0.25,
  },
  //   ui
  ui1: {
    //
    fontFamily: EFontFamily.REGULAR,
    fontSize: Metrics.fontScale(11),
    lineHeight: Metrics.fontScale(11),
    letterSpacing: Metrics.fontScale(0.43),
  },
  ui2: {
    //
    fontFamily: EFontFamily.CONDENSED,
    fontWeight: '600',
    fontSize: Metrics.fontScale(12),
    lineHeight: Metrics.fontScale(13),
    letterSpacing: Metrics.fontScale(0.6),
    textTransform: 'uppercase',
  },
  ui3: {
    //
    fontFamily: EFontFamily.REGULAR,
    fontSize: Metrics.fontScale(12),
    lineHeight: Metrics.fontScale(16),
    letterSpacing: Metrics.fontScale(0.4),
  },
  big: {
    fontFamily: EFontFamily.CONDENSED,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: Metrics.fontScale(144),
    lineHeight: Metrics.fontScale(144),
    letterSpacing: -0.02,
    textTransform: 'uppercase',
  },
  big2: {
    fontFamily: EFontFamily.CONDENSED,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: Metrics.fontScale(64),
    lineHeight: Metrics.fontScale(64),
    letterSpacing: -1.28,
    textTransform: 'uppercase',
  },
  big3: {
    fontFamily: EFontFamily.CONDENSED,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: Metrics.fontScale(32),
    lineHeight: Metrics.fontScale(32),
    letterSpacing: -1.28,
    textTransform: 'uppercase',
  },
  extra: {
    fontFamily: EFontFamily.CONDENSED,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: Metrics.fontScale(18),
    lineHeight: Metrics.fontScale(18),
    textTransform: 'uppercase',
  },
  formTitle: {
    fontFamily: EFontFamily.CONDENSED,
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: Metrics.fontScale(48),
    lineHeight: Metrics.fontScale(48),
    textTransform: 'uppercase',
    letterSpacing: -0.96, 
  }
});
