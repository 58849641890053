import languageSlice from '~Store/Slices/LanguageSlice/Language.slice';
import {UmbracoContentManagmentEndpointBuilder} from '../contentManagmentApi';
import {tracker} from '~Services/Amplitude/hooks/tracker';

export interface ILanguage {
  isoCode: string;
  cultureName: string;
  isDefault: boolean;
  isMandatory: boolean;
  _createDate: string;
  _id: string;
  _updateDate: string;
  _links: {
    self: {
      href: string;
    };
    root: {
      href: string;
    };
  };
}

export interface ILanguageResponse {
  _embedded: {
    languages: ILanguage[];
  };
}

export const createLanguageEndpoint = (
  builder: UmbracoContentManagmentEndpointBuilder,
) => ({
  getLanguages: builder.query<ILanguageResponse, void>({
    query: () => ({
      url: '/language',
      method: 'GET',
      apiVersion: '2',
    }),
    async onQueryStarted(_, {dispatch, queryFulfilled}) {
      try {
        const {data} = await queryFulfilled;
        if (data._embedded && data._embedded.languages) {
          let languages = data._embedded.languages;
          dispatch(languageSlice.actions.setLanguages(languages));
        }
      } catch (err) {
        // Alert.alert('Member Error!');
        // tracker.errorEncountered('umbraco', 'Error querying languages');
      }
    },
  }),
});
