import {pathToRegexp} from 'path-to-regexp';

const addDash = (i: string) =>
  i.substring(0, 8) +
  '-' +
  i.substring(8, 12) +
  '-' +
  i.substring(12, 16) +
  '-' +
  i.substring(16, 20) +
  '-' +
  i.substring(20);

const documentLinkToUuid = (url: string): string | undefined => {
  if (url && url.startsWith('umb://document')) {
    const pathName = url.replace('umb://document', '/document');
    const regex = pathToRegexp('/document/:id', [], {});

    if (regex.test(pathName)) {
      const exec = regex.exec(pathName);
      if (exec) {
        const [, id] = exec;
        if (id) {
          return addDash(id);
        }
      }
    }
  }
  return;
};

export default documentLinkToUuid;
