import ThemeProvider from 'design-system/src/Provider/ThemeProvider/ThemeProvider';
import defaultTheme from 'design-system/src/Theme/defaultTheme';
import React from 'react';
import {ReactNode} from 'react';

const primaryColor = '#C3A059';
const mapperColor = {
  [defaultTheme.typography.color.primary]: primaryColor,
  'rgba(234, 108, 19, 0.5)': 'rgba(195, 160, 89, 0.5)',
};
const AwardsThemeProvider = ({children}: {children: ReactNode}) => {
  return (
    <ThemeProvider
      theme={{
        component: {
          button: {
            contained: {
              customStyle: {
                // background:
                //   'radial-gradient(1247.50% 108.40% at 26.04% 50.00%, #DEA644 0%, #C28422 43.23%, #D6AA55 100%)',
                background:
                  'radial-gradient(100.5% 336.4% at 15.04% 18%, rgb(222, 166, 68) 0%, rgb(194, 132, 34) 43.23%, rgb(214, 170, 85) 100%)',
                borderColor: 'transparent',
              },
            },
          },
          typography: {
            mapColor: color =>
              color === defaultTheme.typography.color.primary
                ? '#C3A059'
                : color,
          },
          textInput: {
            mapColor: (color?: string) =>
              color && !!mapperColor[color] ? mapperColor[color] : color,
          },
        },
      }}>
      {children}
    </ThemeProvider>
  );
};

export default AwardsThemeProvider;
