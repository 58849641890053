import {SubscriptionBody} from '../interface/umbraco.interface';
import {TraceApiEndpointBuilder} from '../traceApi';

export const createSubscriptionEndpoint = (
  builder: TraceApiEndpointBuilder,
) => ({
  getUserSubscription: builder.query<any, void>({
    query: () => ({
      url: '/me/subscription',
      method: 'GET',
    }),
  }),
  updateUserSubscription: builder.query<any, SubscriptionBody>({
    query: body => ({
      url: '/me/subscription',
      method: 'PUT',
      body,
    }),
  }),
  getAndroidAccessToken: builder.query<{token: string}, void>({
    query: () => ({
      url: '/store/android/token',
      method: 'GET',
    }),
  }),
});
