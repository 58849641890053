import axios from 'axios';
import apiConfig from '~Configs/apiConfig';

export const umbracoCdnAxiosInstance = axios.create({
  baseURL: apiConfig.restUrl,
  headers: {
    'Umb-Project-Alias': apiConfig.umbProjectAlias,
    'Api-Key': apiConfig.umbApiKey,
  },
});
