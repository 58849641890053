import {AxiosRequestConfig, AxiosError} from 'axios';
import {BaseQueryFn} from '@reduxjs/toolkit/dist/query';
import {traceAxiosInstance} from './trace.axios';
// import {getFirebaseTokenId} from '~Hooks/useFirebaseAuth';
// import auth from '@react-native-firebase/auth';

// import {getFirebaseApp} from '~Firebase/config';

const getFirebaseTokenId = async (): Promise<string | undefined> => {
  // try {
  //   const app = await getFirebaseApp();
  //   if (app) {
  //     const authInstance = auth(app);

  //     if (authInstance.currentUser) {
  //       return authInstance.currentUser.getIdToken(true);
  //     }
  //   }
  // } catch (error) {
  //   throw error;
  // }
  return '';
};

export const traceBaseQueryFn: BaseQueryFn<
  {
    url: string;
    baseUrl?: string;
    method?: AxiosRequestConfig['method'];
    body?: AxiosRequestConfig['data'];
    params?: AxiosRequestConfig['params'];
    apiVersion?: string;
    contentType?: string;
  },
  unknown,
  unknown,
  {}
> = async ({
  url,
  method,
  body: data,
  params,
  apiVersion = '2',
  contentType,
}) => {
  try {
    const token = await getFirebaseTokenId();
    const result = await traceAxiosInstance({
      url,
      method,
      data,
      params,
      headers: {
        'Api-Version': apiVersion,
        'Content-Type': contentType,
        Authorization: 'Bearer ' + token,
      },
    });

    return {
      data: result.data,
      error: undefined,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {status: err.response?.status, data: err.response?.data},
      data: undefined,
    };
  }
};
