import {UmbracoResponse} from '~Api/Umbraco/interface/UmbracoResponse';
import {Form} from '~Api/Umbraco/interface/UmbracoTypes';
import {UmbracoContentManagmentEndpointBuilder} from '../contentManagmentApi';

export const createFormEndpoint = (
  builder: UmbracoContentManagmentEndpointBuilder,
) => ({
  getForms: builder.query<UmbracoResponse<{forms: Form[]}>, void>({
    query: () => ({
      url: '/forms',
      method: 'GET',
      apiVersion: '2.1',
    }),
  }),
  postForms: builder.query<
    UmbracoResponse<{forms: Form[]}>,
    {id: string; body: Record<string, any>}
  >({
    query: ({id, body}) => ({
      url: `/forms/${id}/entries`,
      method: 'POST',
      apiVersion: '2.1',
      body,
    }),
  }),
});
