import React from 'react';
import ContentLoader, {Rect} from 'react-content-loader/native';
import {StyleProp, StyleSheet, ViewStyle} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import {barLoaderSizeStyles} from './BarLoader.styles';

const BarLoader = ({
  type,
  style,
  customHeight,
  customWidth,
}: {
  type?: keyof typeof barLoaderSizeStyles;
  style?: StyleProp<ViewStyle>;
  customHeight?: number;
  customWidth?: number;
}) => {
  const size = type ?? 'small';
  const height = customHeight ?? barLoaderSizeStyles[size].height;
  const width = customWidth ?? barLoaderSizeStyles[size].width;

  return (
    <ContentLoader
      style={StyleSheet.flatten([
        style,
        {
          backgroundColor: 'rgba(23, 24, 29, 0.7)',
          borderRadius: Metrics.verticalScale(height * 0.48),
          overflow: 'hidden',
        },
      ])}
      height={height}
      width={width}
      speed={1}
      backgroundColor={'rgba(23, 24, 29, 0.7)'}
      foregroundColor={'#111216'}>
      <Rect
        x="0"
        y="0"
        rx="16"
        ry="16"
        width={`${width}`}
        height={`${height}`}
        fill={'#111216'}
      />
    </ContentLoader>
  );
};

export default BarLoader;
