import React, { useEffect } from 'react'
import { useI18next } from 'gatsby-plugin-react-i18next';
import getRessources from '@src/tools/getRessources';

interface ITranslationLoaderProps {
    language: string
}

const TranslationLoader = (props: ITranslationLoaderProps) => {
    const { language } = props
    const { i18n } = useI18next();

    useEffect(() => {
        getRessources(language).then((data) => {
            data.edges.forEach(({ node }) => {
                i18n.addResourceBundle(node.language, node.ns, node.data, false, true)
            })
        })
    }, [language]) 
    return null;
}

export default TranslationLoader