import {UmbracoCdnApiEndpointBuilder} from '../umbracoCdnApi';
import memberSlice from '~Store/Slices/Member.slice';

export interface FilterProperty {
  alias: string;
  value: string;
  match: 'CONTAINS' | 'LIKE';
}

export interface FilterBody {
  contentTypeAlias: string;
  properties: Array<FilterProperty>;
  // properties: [{
  //     "alias": "description",
  //     "value": "ipsum",
  //     "match": "CONTAINS"
  // }]
}

export const createContentEndpoint = (
  builder: UmbracoCdnApiEndpointBuilder,
) => ({
  searchContent: builder.query<any, {keyWord: string; page: number}>({
    query: ({keyWord, page}) => ({
      url: `/content/search?term=${keyWord}&page=${page}&pageSize=1000`,
      method: 'GET',
      apiVersion: '2.1',
    }),
  }),
  filterSearchContent: builder.query<any, {body: FilterBody}>({
    query: ({body}) => ({
      url: '/content/filter',
      method: 'POST',
      apiVersion: '2.1',
      body,
    }),
  }),
  countSavedContent: builder.mutation<
    {
      _totalItems: number;
      _totalPages: number;
      _page: number;
      _pageSize: number;
    },
    {contentId: string}
  >({
    query: ({contentId}) => ({
      url: 'https://cdn.umbraco.io/content/filter?page=1&pageSize=1',
      method: 'POST',
      apiVersion: '2',
      body: {
        contentTypeAlias: 'memberSaved',
        properties: [
          {
            alias: 'contentLink',
            value: contentId,
            match: 'LIKE',
          },
        ],
      },
    }),
    async onQueryStarted({contentId}, {dispatch, queryFulfilled}) {
      try {
        const {data} = await queryFulfilled;
        console.log(contentId, data._totalItems);
        if (data) {
          dispatch(
            memberSlice.actions.setBookmarkCount({
              contentId,
              count: data._totalItems ?? 0,
            }),
          );
        }
      } catch (error) {}
    },
  }),
});
