import React from 'react';
import defaultTheme from '../../Theme/defaultTheme';
import {ThemeContextProvider, ThemeState} from './ThemeContext';
import {ReactNode} from 'react';

interface IThemeProviderProps {
  theme?: Partial<ThemeState>;
  children: ReactNode;
}

const ThemeProvider = (props: IThemeProviderProps) => {
  const {theme = {}, children} = props;
  return (
    <ThemeContextProvider
      value={{
        ...defaultTheme,
        ...theme,
      }}>
      {children}
    </ThemeContextProvider>
  );
};

export default ThemeProvider;
