import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import {traceApi} from '~Api/TraceApi/traceApi';
import { RootState } from '..';

// First, create the thunk
const fetchLiveStreamToken = createAsyncThunk(
  'liveStream/fetchLiveStreamToken',
  async (_, {dispatch, getState}) => {
    const state = getState() as RootState;
    const {timestamp, token} = state.liveStream;
    const timestampDiff = dayjs(timestamp).diff(dayjs(), 'minutes');
    if (timestampDiff < 5) {
      return {
        timestamp,
        token,
      };
    }
    const response = await dispatch(
      traceApi.endpoints.getLiveStreamToken.initiate(),
    );
    return response.data;
  },
);

interface LiveStreamState {
  timestamp: string;
  token: string;
}

const initialState = {
  timestamp: '',
  token: '',
} as LiveStreamState;

// Then, handle actions in your reducers:
const liveStreamSlice = createSlice({
  name: 'liveStream',
  initialState,
  reducers: {
    setListeStreamToken(
      state,
      action: PayloadAction<Pick<LiveStreamState, 'timestamp' | 'token'>>,
    ) {
      const {timestamp, token} = action.payload;
      state.timestamp = timestamp;
      state.token = token;
      return state;
    },
  },
  extraReducers: builder => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(fetchLiveStreamToken.fulfilled, (state, action) => {
      if (action.payload) {
        state.timestamp = action.payload.timestamp;
        state.token = action.payload.token;
      }
    });
  },
});

export default liveStreamSlice;
