import {Member} from '~Api/Umbraco/interface/Member.interface';
import memberSlice from '~Store/Slices/Member.slice';
import {UmbracoContentManagmentEndpointBuilder} from '../contentManagmentApi';
import {Alert} from 'react-native';
import documentLinkToUuid from '~Umbraco/Utils/documentLinkToUuid';
import {tracker} from '~Services/Amplitude/hooks/tracker';

interface ICreateMemberForm {
  email: string;
  emailAddress: string;
  password: string;
  name: string;
  username: string;
  memberTypeAlias: string;
  lastName: string;
  firstName: string;
  phone: string;
  gender: string;
  localisation: string;
  WifiOnly: string;
  downloadQuality: string;
  allowCookies: string;
  region: string;
  firebaseUid: string;
}

interface IAuthMember {
  //   grant_type?: 'password';
  username: string;
  password: string;
}

export const memberTransformer = (member: Member): Member => {
  if (member.memberDatasLink) {
    const memberDatasId = documentLinkToUuid(member.memberDatasLink);
    if (memberDatasId) {
      return {
        ...member,
        memberDatasId: memberDatasId,
      };
    }
  }
  return member;
};

export const createMembersEndpoint = (
  builder: UmbracoContentManagmentEndpointBuilder,
) => ({
  getMemberByUsername: builder.query<Member, string>({
    query: username => ({
      url: `/member/${username}`,
      method: 'GET',
    }),
    transformResponse(response: Member) {
      return memberTransformer(response);
    },
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      try {
        const {data} = await queryFulfilled;
        dispatch(memberSlice.actions.setMember(data));
      } catch (err) {
        // tracker.errorEncountered(
        //   'umbraco',
        //   'Error querying member by username',
        // );
        // Alert.alert('Member Error!');
      }
    },
  }),
  getMemberExist: builder.query<Member, string>({
    query: username => ({
      url: `/member/${username}`,
      method: 'GET',
    }),
    transformResponse(response: Member) {
      return memberTransformer(response);
    },
  }),
  updateMember: builder.query<Member, {username: string; data: Member}>({
    query: ({username, data}) => ({
      url: `/member/${username}`,
      method: 'PUT',
      body: {...data, memberDatasId: undefined},
    }),
    transformResponse(response: Member) {
      return memberTransformer(response);
    },
    async onQueryStarted(id, {dispatch, queryFulfilled}) {
      try {
        const {data} = await queryFulfilled;
        dispatch(memberSlice.actions.setMember(data));
        if (data) {
          tracker.signup.accountUpdated();
        }
      } catch (err) {
        console.log('err', err);
        Alert.alert('Member Error!');
        tracker.errorEncountered('umbraco', 'Error updating member');
      }
    },
  }),
  postMember: builder.query<any, {body: ICreateMemberForm}>({
    query: ({body}) => ({
      url: '/member',
      method: 'POST',
      body: {...body, memberDatasId: undefined},
    }),
  }),
  authMember: builder.query<any, {body: IAuthMember}>({
    query: ({body}) => ({
      url: '/oauth/token',
      method: 'POST',
      body: {
        ...body,
        grant_type: 'password',
      },
      //   contentType: 'application/x-www-form-urlencoded',
      apiVersion: '2',
    }),
  }),
  checkMemberEmailExist: builder.query<Member, string>({
    query: username => ({
      url: `/member/${username}`,
      method: 'GET',
    }),
  }),
});
