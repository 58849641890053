import React from "react";

export const defaultValue = {
  modal: false,
  closeTo: null,
};
const ModalRoutingContext = React.createContext<{
  modal: boolean;
  closeTo: string | null;
}>(defaultValue);

export default ModalRoutingContext;
