import {init} from '@amplitude/analytics-browser';
import apiConfig from '~Configs/apiConfig';

export const initAmplitude = () => {
  if (apiConfig.amplitude.apiKey) {
    init(apiConfig.amplitude.apiKey, undefined, {
      logLevel: apiConfig.amplitude.debugLevel || 0,
      serverZone: 'EU',
    });
  }
};
