import React from 'react';
import ContentLoader, {Rect} from 'react-content-loader/native';
import {StyleSheet, View} from 'react-native';
import Metrics from '../../../../Theme/Metrics';
import {selectSize} from '../../../../tools/CardSizeTools';
import {GenericCardProps} from '../../../Card/GenericCard/GenericCard';
import BarLoader from '../BarLoader/BarLoader';
import {barLoaderSizeStyles} from '../BarLoader/BarLoader.styles';

const CardLoader = (
  props: GenericCardProps & {infoSize?: keyof typeof barLoaderSizeStyles},
) => {
  const {infoSize} = props;
  const size = React.useCallback(selectSize, [props])(props, false);

  return (
    <View>
      <ContentLoader
        height={Metrics.verticalScale(size.height)}
        width={Metrics.horizontalScale(size.width)}
        speed={1}
        backgroundColor={'rgba(23, 24, 29, 0.7)'}
        foregroundColor={'#111216'}>
        <Rect
          x="0"
          y="0"
          rx="5"
          ry="5"
          width={`${Metrics.horizontalScale(size.width)}`}
          height={`${Metrics.verticalScale(size.height)}`}
        />
      </ContentLoader>
      <View style={styles.bar}>
        <BarLoader type={infoSize} />
      </View>
    </View>
  );
};

export default CardLoader;

const styles = StyleSheet.create({
  bar: {marginTop: Metrics.verticalScale(8)},
});
