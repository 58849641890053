
import {FreeApiEndpointBuilder} from '../freeApi';
import { ProgramFile } from '../interface/programFile';

export const createProgramFileEndpoint = (builder: FreeApiEndpointBuilder) => ({
  getProgramFile: builder.query<
    ProgramFile,
    {url: string}
  >({
    query: ({url}) => ({
      url: url,
      method: 'GET',
    }),
  }),
});

