import CustomFastImage from 'design-system/src/Components/CustomFastImage/CustomFastImage.web';
import {CustomFastImageProps} from 'design-system/src/Components/CustomFastImage/CustomFastImageProps';
import Typography from 'design-system/src/Components/Typography/Typography';
import Metrics from 'design-system/src/Theme/Metrics';
import useBreakpoint from 'design-system/src/WebComponent/Grid/hooks/useBreakpoint';
import React, {ReactNode} from 'react';
import {View} from 'react-native';
import LinearGradient from 'react-native-linear-gradient';

export type FormHeader = {
  children?: ReactNode;
  title?: string | ReactNode;
  noImage?: boolean;
  cover: CustomFastImageProps['source'];
  coverDesktop?: CustomFastImageProps['source'];
};

export default function FormHeader(props: FormHeader) {
  const {children, title, noImage, cover: defaultCover, coverDesktop} = props;
  const aspectRatio = 16 / 9;
  const {isMobile} = useBreakpoint();
  const cover = isMobile
    ? defaultCover || coverDesktop
    : coverDesktop || defaultCover;
  return (
    <View
      style={{
        justifyContent: 'space-between',
        width: '100%',
        height: 'auto',
        aspectRatio,
        position: 'relative',
        paddingBottom: Metrics.verticalScale(28),
      }}>
      <View>{children}</View>
      {!noImage && (
        <>
          <LinearGradient
            colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
            useAngle={true}
            angle={180}
            locations={[0, 0.8, 1]}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 8,
            }}
          />
          <LinearGradient
            colors={['rgba(17, 18, 22, 0)', 'rgba(17, 18, 22, 0.8)', '#111216']}
            useAngle={true}
            angle={180}
            locations={[0, 0.8, 1]}
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 9,
            }}
          />
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              aspectRatio,
            }}>
            <CustomFastImage
              source={cover}
              width={'100%'}
              height={'100%'}
              resizeMode="cover"
              wrapperStyles={{
                aspectRatio,
                width: '100%',
                height: '100%',
                overflow: 'hidden',
              }}
            />
          </View>
        </>
      )}
      {/* <View style={{zIndex: 10, alignItems: 'center'}}>
        <Typography
          numberOfLines={3}
          style={{
            textAlign: 'center',
            position: 'relative',
            width: Metrics.horizontalScale(noImage ? 226 : 300),
            marginHorizontal: 'auto',
          }}
          variant="h2">
          {title}
        </Typography>
      </View> */}
    </View>
  );
}
