import 'raf/polyfill';
import {GatsbyBrowser} from 'gatsby';
// @ts-ignore
import React from 'react';
import AppProvider from './src/AppProvider';
import {createRoot} from 'react-dom/client';
import {Renderer} from 'react-dom';
import './src/assets/css/main.css';
// import WrapPageElement from "./src/components/general/WrapPageElement/WrapPageElement";

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({
  element,
}) => {
  return <AppProvider runContext={'browser'}>{element}</AppProvider>;
};

// export const wrapPageElement = ({ element, props }) => (
//   <WrapPageElement {...props}>{element}</WrapPageElement>
// );

//
// Ceci est un patch pour l'erreur sur le build de production avec l'erreur React :
// > Hydration failed because the initial UI does not match what was rendered on the server.
// voir https://github.com/gatsbyjs/gatsby/discussions/31943#discussioncomment-2880513
//
export const replaceHydrateFunction: GatsbyBrowser['replaceHydrateFunction'] =
  () => {
    return ((
      element:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | React.ReactPortal,
      container: Element | DocumentFragment,
    ) => {
      const root = createRoot(container);
      root.render(element);
    }) as Renderer;
  };
