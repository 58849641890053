module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../plugins/layout-wrapper/gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c12fa7f03707fce00f1cda8c9d4f182d"},
    },{
      plugin: require('../../../packages/gatsby-plugin-trace-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locales","languages":["en","fr","pt"],"defaultLanguage":"en","langMapper":{"en":"en-US","fr":"fr","pt":"pt-BR"},"generateDefaultLanguagePage":true,"siteUrl":"https://www.trace.plus","i18nextOptions":{"interpolation":{"escapeValue":false},"compatibilityJSON":"v3","fallbackLng":"en"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
