import React, {createContext} from 'react';
import {IButtonProps} from '../../Components/Button/Button';

export interface TrackingState {
  onButtonPress?: (props: Pick<IButtonProps, 'title'>) => void;
}

const TrackingContext = createContext<TrackingState>({});

const TrackingContextProvider = TrackingContext.Provider;

const useTrackingContext = () => {
  return React.useContext<TrackingState>(TrackingContext);
};

export {TrackingContextProvider, useTrackingContext};
export default TrackingContext;
