import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {CSSTransition} from 'react-transition-group';
import './PageModal.styles.scss';
import clsx from 'clsx';

export interface IPageModalProps {
  onClose?: React.MouseEventHandler<HTMLDivElement | HTMLButtonElement>;
  closable?: boolean;
  show: any;
  children?: React.ReactNode;
}
const PageModal = (props: IPageModalProps) => {
  const {onClose, children, show} = props;

  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    return ReactDOM.createPortal(
      <CSSTransition in={show} timeout={{enter: 0, exit: 100}}>
        <div className={clsx({modal: true, show})} onClick={onClose}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-body">{children}</div>
          </div>
        </div>
      </CSSTransition>,
      document.getElementById('___gatsby')!,
    );
  }
  return null;
};

export default PageModal;
