import {
  EqualityFn,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
  useStore,
} from "react-redux";
import isEqual from "lodash/isEqual";
import store, { RootState } from ".";

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = <TSelected>(
  selector: (state: RootState) => TSelected
): TSelected =>
  useSelector<RootState, TSelected>(selector, isEqual as EqualityFn<TSelected>);

export const useAppStore = () => useStore<RootState>();
