import {SvgProps} from 'react-native-svg';
import ArrowDown from './svg/arrows/ArrowDown.svg';
import ArrowUp from './svg/arrows/ArrowUp.svg';
import ArrowLeft from './svg/arrows/ArrowLeft.svg';
import ArrowRight from './svg/arrows/ArrowRight.svg';
import Account from './svg/icon_menu/Account.svg';
import Cast from './svg/icon_menu/Cast.svg';
import Burger from './svg/icon_menu/Burger.svg';
import Search from './svg/icon_menu/Search.svg';
import Home from './svg/icon_tap_bar/Home.svg';
import Learn from './svg/icon_tap_bar/Learn.svg';
import Listen from './svg/icon_tap_bar/Listen.svg';
import Menu from './svg/icon_tap_bar/Menu.svg';
import Watch from './svg/icon_tap_bar/Watch.svg';
import Favoris from './svg/icon_36/Favoris.svg';
import Share from './svg/icon_36/Share.svg';
import Favoris32 from './svg/icon_32/Favoris.svg';
import Share32 from './svg/icon_32/Share.svg';
import QuizzIcon from './svg/icon_32/QuizzIcon.svg';
import Channels from './svg/icon_32/Channels.svg';
import Desktop from './svg/icon_32/Desktop.svg';
import Free from './svg/icon_32/Free.svg';
import LiveEvent from './svg/icon_32/LiveEvent.svg';
import Months from './svg/icon_32/Months.svg';
import Ps5 from './svg/icon_32/Ps5.svg';
import Tablet from './svg/icon_32/Tablet.svg';
import TvGuide from './svg/icon_32/TvGuide.svg';
import Year from './svg/icon_32/Year.svg';
import Invalide from './svg/icon_24/Invalide.svg';
import Exclude from './svg/icon_40/Exclude.svg';
import Success from './svg/notification/success.svg';
import Failed from './svg/notification/failed.svg';
import FormIcon from './svg/form.svg';
import ClockSub from './svg/icon_32/ClockSub.svg';
import FavorisFill32 from './svg/icon_32/FavorisFill.svg';
import QuizzPerso from './svg/icon_32/QuizzPerso.svg';

import Multimedia from './svg/icon_28/multimedia.svg';
import Badges from './svg/icon_28/badges.svg';
import Videos from './svg/icon_28/videos.svg';
import Videos48 from './svg/icon_28/videos-48.svg';
import Quizzes from './svg/icon_28/quizzes.svg';
import Quizzes48 from './svg/icon_28/quizzes-48.svg';
import Hours from './svg/icon_28/hours.svg';

import Bullet from './svg/bullet.svg';

import Instagram from './svg/social/instagram.svg';
import Twitter from './svg/social/twitter.svg';
import Spotify from './svg/social/spotify.svg';
import Tiktok from './svg/social/TikTok.svg';

// Quiz Icon

import Artistic from './svg/icon_quizz/Artistic.svg';
import Conventional from './svg/icon_quizz/Conventional.svg';
import Enterprising from './svg/icon_quizz/Enterprising.svg';
import Investigative from './svg/icon_quizz/Investigative.svg';
import Personalitie from './svg/icon_quizz/Personalitie.svg';
import Realistic from './svg/icon_quizz/Realistic.svg';
import Social from './svg/icon_quizz/Social.svg';
import ToolKit from './svg/icon_quizz/Toolkit.svg';

// Logo and parteners

import CanalPlus from './svg/logo/CanalPlus.svg';
import Durex from './svg/logo/Durex.svg';
import Google from './svg/logo/Google.svg';
import Johannesburg from './svg/logo/Johannesburg.svg';
import Leroy from './svg/logo/Leroy.svg';
import Schneider from './svg/logo/Schneider.svg';
import Trace from './svg/logo/Trace.svg';
import Unesco from './svg/logo/Unesco.svg';
import Union from './svg/logo/Union.svg';
import Veritas from './svg/logo/Veritas.svg';
import TraceLogo from './svg/logo/TraceLogo.svg';
import BadgeHelp from './svg/badge-help.svg';
import TraceT from './svg/logo/TraceT.svg';
import Subscription from './svg/logo/Subscription.svg';
import PlayBig from './svg/play-big.svg';
import TraceBig from './svg/Trace.svg';

// color
import ColorUser from './svg/colors/color-user.svg';
import ColorArt from './svg/colors/color-art.svg';
import ColorSocial from './svg/colors/color-social.svg';
import ColorConv from './svg/colors/color-conv.svg';
import ColorInvest from './svg/colors/color-invest.svg';
import ColorEnt from './svg/colors/color-ent.svg';

// 36
import FavorisFill from './svg/icon_36/FavorisFill.svg';

// store
import AppStore from './svg/app-store.svg';
import GooglePlay from './svg/google-play.svg';
import Huawei from './svg/HUWAEI.svg';

import {
  Abonnement,
  Audio,
  Bookmark,
  Certificat,
  RadioEmpty,
  RadioOff,
  RadioOn,
  CheckWatch,
  Close,
  Delete,
  Download,
  Effacer,
  Error,
  Favoris as Favoris24,
  FavorisMin,
  Help,
  Link,
  LiveEvent as LiveEvent24,
  LiveTv,
  News,
  Next,
  Notification,
  OnDemande,
  Opportunity,
  Parametre,
  Personnality,
  Picture,
  Play,
  Podcast,
  Previous,
  Profil,
  Radio,
  Retour,
  SelectForm,
  Share as Share24,
  SortBy,
  Teaser,
  Telechargement,
  TvGuide as TvGuide24,
  DownloadInProgress as DownloadInProgress24,
  CheckCircle,
  Achievment,
  Tele,
  FavorisFill as FavorisFill24,
  Apple,
  Email,
  Facebook,
  Google as GoogleG,
  More,
} from './svg/icon_24/Icon24';

import {
  ArrowDown as ArrowDown16,
  Check,
  CheckEmpty,
  CheckOn,
  Clock,
  Date,
  Download as Download16,
  DownloadFinished,
  DownloadInProgress,
  Effacer as Effacer16,
  Favoris as Favoris16,
  Learn as Learn16,
  Like,
  Message,
  Play as Play16,
  Right,
  Select,
  Share as Share16,
  ThreeDot,
  Watch as Watch16,
  CheckFilled,
  Pause,
  CaretUp,
  CaretDown,
  ArrowRight16,
  Horaire,
  FavorisFill as FavorisFill16,
  Thumbup,
  Thumbdown,
  CloseBold,
  CrossedEye,
  Eye,
  LiveEvent16,
  LiveTv16,
  News16,
  OnDemand16,
  PodCast16,
  Radio16,
} from './svg/icon_16/icon16';

import CirclePrevious from './svg/player/CirclePrevious.svg';
import CircleForward from './svg/player/CircleForward.svg';
import VideoPause from './svg/player/VideoPause.svg';
import VideoPlay from './svg/player/VideoPlay.svg';
import Gallery from './svg/Gallery.svg';
import Restore from './svg/restore.svg';
import Crown from './svg/icon_32/crown_trace.svg';

export type IconSize =
  | '108'
  | '48'
  | '36'
  | '32'
  | '24'
  | '16'
  | '76'
  | 'default';
export type IconName = keyof typeof IconesObject;

type IconData = {[size in IconSize]?: React.FC<SvgProps>} & {
  default: React.FC<SvgProps>;
};

const IconesObject = {
  crown: {
    default: Crown,
    32: Crown,
  },
  restore: {
    default: Restore,
    24: Restore,
  },
  more: {
    default: More,
  },
  gallery: {
    default: Gallery,
  },
  circlePrevious: {
    default: CirclePrevious,
  },
  circleForward: {
    default: CircleForward,
  },
  pause: {
    default: Pause,
    24: VideoPause,
  },
  canalPlus: {
    default: CanalPlus,
  },
  durex: {
    default: Durex,
  },
  google: {
    default: Google,
  },
  johannesburg: {
    default: Johannesburg,
  },
  leroy: {
    default: Leroy,
  },
  schneider: {
    default: Schneider,
  },
  traceBig: {
    default: TraceBig,
  },
  trace: {
    default: Trace,
  },
  unesco: {
    default: Unesco,
  },
  union: {
    default: Union,
  },
  veritas: {
    default: Veritas,
  },
  artistic: {
    32: Artistic,
    default: Artistic,
  },
  conventional: {
    32: Conventional,
    default: Conventional,
  },
  enterprising: {
    32: Enterprising,
    default: Enterprising,
  },
  investigative: {
    32: Investigative,
    default: Investigative,
  },
  personalitie: {
    32: Personalitie,
    default: Personalitie,
  },
  realistic: {
    32: Realistic,
    default: Realistic,
  },
  toolKit: {
    32: ToolKit,
    default: ToolKit,
  },
  social: {
    32: Social,
    default: Social,
  },
  'arrow-down': {
    24: ArrowDown,
    16: ArrowDown16,
    default: ArrowDown,
  },
  'arrow-up': {
    24: ArrowUp,
    default: ArrowUp,
  },
  'arrow-left': {
    24: ArrowLeft,
    default: ArrowLeft,
  },
  'arrow-right': {
    24: ArrowRight,
    16: ArrowRight16,
    default: ArrowRight,
  },
  account: {
    24: Account,
    default: Account,
  },
  cast: {
    24: Cast,
    default: Cast,
  },
  burger: {
    24: Burger,
    default: Burger,
  },
  search: {
    24: Search,
    default: Search,
  },
  home: {
    24: Home,
    default: Home,
  },
  learn: {
    24: Learn,
    16: Learn16,
    default: Learn,
  },
  listen: {
    24: Listen,
    default: Listen,
  },
  menu: {
    24: Menu,
    default: Menu,
  },
  watch: {
    24: Watch,
    16: Watch16,
    default: Watch,
  },
  //
  favoris: {
    36: Favoris,
    32: Favoris32,
    24: Favoris24,
    16: Favoris16,
    default: Favoris,
  },
  'favoris-fill': {
    36: FavorisFill,
    32: FavorisFill32,
    24: FavorisFill24,
    16: FavorisFill16,
    default: FavorisFill,
  },
  share: {
    36: Share,
    32: Share32,
    24: Share24,
    16: Share16,
    default: Share,
  },
  channels: {
    32: Channels,
    default: Channels,
  },
  quizz: {
    32: QuizzIcon,
    default: QuizzIcon,
  },
  desktop: {
    32: Desktop,
    default: Desktop,
  },
  free: {
    32: Free,
    default: Free,
  },
  'live-event': {
    32: LiveEvent,
    24: LiveEvent24,
    16: LiveEvent16,
    default: LiveEvent,
  },
  months: {
    32: Months,
    default: Months,
  },
  ps5: {
    32: Ps5,
    default: Ps5,
  },
  tablet: {
    32: Tablet,
    default: Tablet,
  },
  tvguide: {
    32: TvGuide,
    24: TvGuide24,
    default: TvGuide,
  },
  year: {
    32: Year,
    default: Year,
  },
  abonnement: {
    24: Abonnement,
    default: Abonnement,
  },
  audio: {
    24: Audio,
    default: Audio,
  },
  bookmark: {
    24: Bookmark,
    default: Bookmark,
  },
  certificat: {
    24: Certificat,
    default: Certificat,
  },
  'check-empty': {
    24: RadioEmpty,
    default: RadioEmpty,
  },
  'radio-off': {
    24: RadioOff,
    default: RadioOff,
  },
  'radio-on': {
    24: RadioOn,
    default: RadioOn,
  },
  'check-watch': {
    24: CheckWatch,
    default: CheckWatch,
  },
  close: {
    24: Close,
    16: CloseBold,
    default: Close,
  },
  delete: {
    24: Delete,
    default: Delete,
  },
  download: {
    24: Download,
    16: Download16,
    default: Download,
  },
  effacer: {
    24: Effacer,
    16: Effacer16,
    default: Effacer,
  },
  error: {
    24: Error,
    default: Error,
  },
  favorismin: {
    24: FavorisMin,
    default: FavorisMin,
  },
  help: {
    24: Help,
    default: Help,
  },
  invalide: {
    24: Invalide,
    default: Invalide,
  },
  link: {
    24: Link,
    default: Link,
  },
  livetv: {
    24: LiveTv,
    16: LiveTv16,
    default: LiveTv,
  },
  news: {
    24: News,
    16: News16,
    default: News,
  },
  next: {
    24: Next,
    default: Next,
  },
  notification: {
    24: Notification,
    default: Notification,
  },
  ondemande: {
    24: OnDemande,
    16: OnDemand16,
    default: OnDemande,
  },
  opportunity: {
    24: Opportunity,
    default: Opportunity,
  },
  parametre: {
    24: Parametre,
    default: Parametre,
  },
  personnality: {
    24: Personnality,
    default: Personnality,
  },
  picture: {
    24: Picture,
    default: Picture,
  },
  play: {
    24: Play,
    16: Play16,
    76: VideoPlay,
    default: Play,
  },
  podcast: {
    24: Podcast,
    16: PodCast16,
    default: Podcast,
  },
  previous: {
    24: Previous,
    default: Previous,
  },
  profil: {
    24: Profil,
    default: Profil,
  },
  radio: {
    24: Radio,
    16: Radio16,
    default: Radio,
  },
  retour: {
    24: Retour,
    default: Retour,
  },
  selectform: {
    24: SelectForm,
    default: SelectForm,
  },
  sortby: {
    24: SortBy,
    default: SortBy,
  },
  teaser: {
    24: Teaser,
    default: Teaser,
  },
  telechargement: {
    24: Telechargement,
    default: Telechargement,
  },
  check: {
    16: Check,
    default: Check,
  },
  checkempty: {
    16: CheckEmpty,
    default: CheckEmpty,
  },
  checkon: {
    16: CheckOn,
    default: CheckOn,
  },
  checkfilled: {
    16: CheckFilled,
    default: CheckFilled,
  },
  clock: {
    16: Clock,
    default: Clock,
  },
  date: {
    16: Date,
    default: Date,
  },
  'download-finished': {
    16: DownloadFinished,
    default: DownloadFinished,
  },
  'download-inprogress': {
    24: DownloadInProgress24,
    16: DownloadInProgress,
    default: DownloadInProgress,
  },
  like: {
    16: Like,
    default: Like,
  },
  message: {
    16: Message,
    default: Message,
  },
  right: {
    16: Right,
    default: Right,
  },
  select: {
    16: Select,
    default: Select,
  },
  'three-dot': {
    16: ThreeDot,
    default: ThreeDot,
  },
  exclude: {
    40: Exclude,
    default: Exclude,
  },
  success: {
    default: Success,
  },
  failed: {
    default: Failed,
  },
  'trace-logo': {
    default: TraceLogo,
  },
  form: {
    default: FormIcon,
  },
  'check-circle': {
    default: CheckCircle,
  },
  'caret-up': {
    default: CaretUp,
  },
  'caret-down': {
    default: CaretDown,
  },
  'badge-help': {
    default: BadgeHelp,
  },
  'trace-t': {
    default: TraceT,
  },
  'clock-sub': {
    default: ClockSub,
  },
  subscription: {
    default: Subscription,
  },
  horaire: {
    default: Horaire,
  },
  achievment: {
    default: Achievment,
  },
  tele: {
    default: Tele,
  },
  multimedia: {
    default: Multimedia,
  },
  badges: {
    default: Badges,
  },
  videos: {
    default: Videos,
    48: Videos48,
  },
  quizzes: {
    default: Quizzes,
    48: Quizzes48,
  },
  hours: {
    default: Hours,
  },
  'play-big': {
    default: PlayBig,
  },
  thumbup: {
    default: Thumbup,
  },
  thumbdown: {
    default: Thumbdown,
  },
  'quizz-perso': {
    default: QuizzPerso,
  },
  'color-user': {
    default: ColorUser,
  },
  'color-art': {
    default: ColorArt,
  },
  'color-social': {
    default: ColorSocial,
  },
  bullet: {
    default: Bullet,
  },
  'color-conv': {
    default: ColorConv,
  },
  'color-invest': {
    default: ColorInvest,
  },
  'color-ent': {
    default: ColorEnt,
  },
  apple: {
    24: Apple,
    default: Apple,
  },
  email: {
    24: Email,
    default: Email,
  },
  facebook: {
    24: Facebook,
    default: Facebook,
  },
  googleG: {
    24: GoogleG,
    default: GoogleG,
  },
  'crossed-eye': {
    16: CrossedEye,
    default: CrossedEye,
  },
  eye: {
    16: Eye,
    default: Eye,
  },
  'app-store': {
    default: AppStore,
  },
  'google-play': {
    default: GooglePlay,
  },
  instagram: {
    default: Instagram,
    24: Instagram,
  },
  twitter: {
    default: Twitter,
    24: Twitter,
  },
  spotify: {
    default: Spotify,
    24: Spotify,
  },
  tiktok: {
    default: Tiktok,
    24: Tiktok,
  },
  huawei: {
    default: Huawei,
    24: Huawei,
  },
};
const Icones: {[_: string]: IconData} = IconesObject;
export default Icones;
